import React from 'react';
import './style.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-phone-number-input/style.css';
import {  Form, FloatingLabel, Button, Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Google from '../google';
import { httpPost } from '../../utils/api';
import {  GOOGLE_CLIENT_ID } from '../../config/endPoint';
import { toast } from 'react-toastify';
import SignUp from '../signUp';
import { useEffect } from 'react';
import leftimg from '../../assets/images/leftBanner.png';
import logo from '../../assets/images/goldlogo.png'

function LoginModal() {
  const { REACT_APP_BASE_URL } = process.env;
  const location = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem('rememberedEmail');
    const password = localStorage.getItem('rememberedPassword');
    if (email) {
      setFormData({
        ...formData,
        email: email,
        password: password || ''
      });
      setRememberMe(true);
    }
  }, []);

  const handleNavigate = () => {
    navigate('/forgot-password');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRememberMeChange = (e) => {
    const { checked } = e.target;
    setRememberMe(checked);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const toggle = () => {
    setVisible(!isVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (rememberMe) {
        localStorage.setItem('rememberedEmail', formData.email);
        localStorage.setItem('rememberedPassword', formData.password);
      } else {
        localStorage.removeItem('rememberedEmail');
        localStorage.removeItem('rememberedPassword');
      }
      await httpPost(`${REACT_APP_BASE_URL}/auth/login`, formData)
        .then((res) => {
          console.log("res data login", res?.data);
          if (res?.data?.status) {
            toast.success('Login Successfully');
            let data = res?.data?.data;
            localStorage.setItem('goldsmith_token', data?.token);
            localStorage.setItem('goldsmith_user', data?.user?.name);
            setTimeout(() => {
              navigate('/account');
            }, 1000);
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const [previousPage, setPreviousPage] = useState(null);

  useEffect(() => {
    if (location.state && location.state.previousPage) {
      setPreviousPage(location.state.previousPage);
    }
  }, [location.state]);

  const handleClose = () => {
    // If the last page was the sign-up page, navigate back two steps, else navigate back one step
    navigate(previousPage ? -2 : -1);
  };

  return (
    <>
      <div className="signup-page-outer signup-modal">
        <div className="d-flex">
          <div className="col-lg-7">
            <div className="sign-up-left">
              <Image src={leftimg} />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="signUp px-5 py-5">
              <Button onClick={handleClose} className="close-btnn">
                <i className="fa-solid fa-xmark"></i>
              </Button>
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="row  justify-content-center">
                  <h1 className="text-center w-100 mt-3 responsive_logo">
                     <Link to={'/'}><Image src={logo} /></Link> 
                    </h1>
                    <h2 className="text-center mb-3 py-3">
                      Login to your Account
                    </h2>
                    <div className="row flex-column align-items-center ">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FloatingLabel label="Email" className="mb-3">
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={(e) => handleChange(e)}
                          />
                        </FloatingLabel>
                        {errors.email && (
                          <p className="error_message">{errors.email}</p>
                        )}
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12">
                        {/* <FloatingLabel label="Password" className="mb-3">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={(e) => handleChange(e)}
                          />
                        </FloatingLabel> */}


                        <FloatingLabel label="Password" className="mb-3 d-flex align-items-center">
                          <Form.Control
                          className="pass-input"
                            type={!isVisible ? "password" : "text"}
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="icon" onClick={toggle}>
                            {!isVisible ? (
                              <i class="fas fa-eye"></i>
                            ) : (
                              <i class="fas fa-eye-slash"></i>
                            )}
                          </span>
                        </FloatingLabel>

                        {errors.password && (
                          <p className="error_message">{errors.password}</p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="d-flex flex-wrap container-rr-outer justify-content-between align-items-center">
                          <label className="container-rr">
                            REMEMBER ME
                            <Form.Control
                              type="checkbox"
                              checked={rememberMe}
                              onChange={handleRememberMeChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <Button
                            onClick={() => handleNavigate()}
                            className="forgot-l"
                          >
                            FORGOT PASSWORD?
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className='forgot-pass-div'>
                    <Button
                            onClick={() => handleNavigate()}
                            className="forgot-l"
                          >
                            FORGOT PASSWORD?
                          </Button>
                    </div>

                    <Button
                      type="submit"
                      className="content-btn register-btn login_btn mt-5"
                    >
                      LOGIN{' '}
                      <span className="arrowUpRight mx-2">
                        <i className="fas fa-arrow-up"></i>
                      </span>
                    </Button>
                    <div className="signup-social-login py-5">
                      <div className="signup-text position-relative">
                        <span>or sign in with</span>
                      </div>
                      <div className="text-center socialImg mt-3 d-flex justify-content-center">
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                          <Google />
                        </GoogleOAuthProvider>
                      </div>
                    </div>
                    <div className="text-center d-flex flex-wrap align-items-center justify-content-center">
                      Don’t have an account?
                      <Link to={'/register'} className="loginn_Btn">
                        SIGN UP
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginModal;
