// PaginationComponent.js
import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import './style.css';
import { Container, Row } from 'react-bootstrap';
import arrowlft from '../../assets/images/arrowLeft.png';

function PaginationComponent({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <>
      <Container>
        <Row className="pagination-wrapper">
          <Pagination className="justify-content-center my-3 py-4">
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={currentPage === index + 1}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Row>
      </Container>
    </>
  );
}

export default PaginationComponent;
