import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./style.css";
import aboutuslft from "../../assets/images/abouttolani.jpg";
import abm1 from "../../assets/images/abm1.png";
import ContactUsForm from "../../components/contactUsForm";
import str1 from "../../assets/images/str1.png";
import str2 from "../../assets/images/str2.png";
import str3 from "../../assets/images/str3.png";
import str4 from "../../assets/images/str4.png";
import str5 from "../../assets/images/str5.png";
import str6 from "../../assets/images/str6.png";

function AboutUs() {
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <Header />

      <div className="about-us-bg">
        <div>
          <h2 className="mb-5">
            About G<span>old</span>smith{" "}
          </h2>
          <p className="mb-0 d-flex justify-content-center">
            <button
              type="button"
              onClick={() => scrollToElement("getintouch")}
              className="content-btn btn btn-primary"
            >
              Contact Us
              <span className="arrowUpRight mx-2">
                <i className="fas fa-arrow-up"></i>
              </span>
            </button>
          </p>
        </div>
      </div>

      <div className="about-us-wrapper pt-4">
        <div className="text-center justify-content-center pt-3 homeRightInfo px-4 position-relative">
          <h2 className="w-50 mx-auto mb-3 pb-2">
            The Ultimate Destination for Luxurious Jewelry and Watches
          </h2>
          <h6 className="text-uppercase mb-4">TURKS & Caicos island</h6>
          <p>
            Welcome To Goldsmith, Your Premier Destination For Exquisite Luxury
            Jewelry And Watches. With Over 30 Years Of Unparalleled Excellence,
            We Are Proud To Be The Oldest And Leading Authority In Luxury
            Shopping At Turks & Caicos Island. Our Commitment To Quality And
            Authenticity Has Made Us The Most Trustworthy Name In The Industry.
            Explore Our Curated Collection Of Renowned Designer Brands, Where
            Each Piece Tells A Story Of Timeless Beauty And Unparalleled
            Craftsmanship. From Stunning Timepieces To Dazzling Jewelry, We
            Offer A Range Of Exceptional Creations That Captivate And Inspire.
            But Our Dedication To Luxury Doesn't Stop There. Discover A World Of
            Indulgence With Our Additional Services, Including Curated
            Sunglasses, Exclusive Perfumes, Designer Footwear, Elegant Clothing,
            And A Wide Selection Of Accessories That Elevate Your Style. At
            Goldsmith, We Believe In Creating Unforgettable Experiences For Our
            Discerning Clientele. Whether You're Seeking A Statement Piece Or A
            Treasured Gift, Our Knowledgeable Team Is Here To Guide You Every
            Step Of The Way. Trust In Our Legacy Of Excellence And Embark On A
            Journey Where Luxury Knows No Bounds.
          </p>
          <ul className="row justify-content-center mt-2 pt-3 homeRightSocialIcons">
            <li className="fb-btn">
              {" "}
              <a
                href="https://www.facebook.com/GoldsmithTCI?mibextid=ZbWKwL"
                target="blank"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>{" "}
            </li>
            <li className="wtsp-btn">
              {" "}
              <a href="https://wa.me/+16493319993" target="_blank">
                {" "}
                <i className="fa-brands fa-whatsapp"></i>
              </a>{" "}
            </li>
            <li className="yt-btn">
              {" "}
              <a href="">
                {" "}
                <i className="fa-brands fa-youtube"></i>
              </a>{" "}
            </li>
            <li className="insta-btn">
              {" "}
              <a
                href="https://www.instagram.com/goldsmith_tci1994/?igsh=dmJ0d3Y5cDNhNXh6"
                target="blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>{" "}
            </li>
          </ul>
        </div>
        <Container>
          <div className="about-us-bio row py-5">
            <Col lg={5} md={12} sm={12}>
              <div className="about-us-lft text-center">
                <Image src={aboutuslft} />
              </div>
            </Col>
            <Col lg={7} md={12} sm={12}>
              <div className="about-us-rght-info homeRightInfo">
                <h2 className="mb-3 pb-3">Govind Tolani (Gopi) and Pushpa Tolani</h2>
                <p>
                The year was 1966, and in the true spirit of entrepreneurship Govind Tolani left his homeland of India to travel aboard in search of new opportunities. <br />

His first stop was the Caribbean Island of Jamaica, where he worked in the duty free industry,  future travels took him to the neighboring island of St. Maarten, where he worked, gained experiences and mastered his trade. <br />
<br />

After getting married to his partner in life Pushpa Tolani, in 1977  they moved together to the Island of Jamaica, to the city of Montego Bay and established their first retail store. <br />

Next stop was to Mainland USA in Miami, Florida. Having opened their first store in the United States on the famous block called Lincoln Road in South Beach Miami.
<br />
<br />
Now with 3 children, Coming full circle in 1994 with a move to a new and upcoming island, opened their new store “Goldsmith” in Providenciales, Turks and Caicos, and have not looked back since.
<br />
After opening our flagship Goldsmith store in 1994, we are now celebrating 30 years on the Turks and Caicos Islands this year in 2024.  The Goldsmith name has become synonymous with offering the highest quality products, focus on customer satisfaction, and a continuous drive to always bring new and innovative products to our loyal clients. 
<br /> <br />
Having now expanded to 5 stores on the chain of islands, the torch has been handed over to their son Ajay Tolani. Grateful to an amazing team, the Goldsmith story continues to reach for the beautiful aqua blue Caribbean skies for generations to come.
                </p>
              </div>
            </Col>
          </div>
          {/* <div className="about-us-member my-4 row justify-content-center">
                        <Col lg={4} md={6} sm={12}>
                            <div className="about-us-member-card">
                                <Image src={abm1} />
                                <h4>About Ajay Tolani</h4>
                                <p>welcome to Goldsmith, your premier destination for exquisite luxury jewelry and watches. With over 30 years of unparalleled excellence, we are proud to be the oldest and leading authority in luxury shopping at Turks & Caicos Island. Our commitment to quality and authenticity has </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className="about-us-member-card">
                                <Image  src={abm1} />
                                <h4>About Ajay Tolani</h4>
                                <p>welcome to Goldsmith, your premier destination for exquisite luxury jewelry and watches. With over 30 years of unparalleled excellence, we are proud to be the oldest and leading authority in luxury shopping at Turks & Caicos Island. Our commitment to quality and authenticity has </p>
                            </div>
                        </Col>

                    </div> */}
        </Container>
      </div>

      <div className="our-store py-4 my-3">
        <h2 className="text-center">Our Stores</h2>
        <div className="our-store-inner my-5 py-4">
          <div className="our-store-inner-card">
            <Image src={str1} />
            <div className="our-store-inner-card-info">
              <h4>The Goldsmith</h4>
              <p>Central Square Plaza, Leeward Highway, Providenciales. </p>
              <p className="category-text">Jewelry ,Watches, Sunglasses , Perfumes , Clothing</p>
              <a href="mailto:gscentralsquare@gmail.com">gscentralsquare@gmail.com</a>
              <a href="tel:+16499464100">+1(649)946-4100</a>
            </div>
          </div>
          <div className="our-store-inner-card">
            <Image src={str2} />
            <div className="our-store-inner-card-info">
            <h4>The Goldsmith</h4>
              <p>Central Square Plaza, Leeward Highway, Providenciales. </p>
              <p className="category-text">Jewelry ,Watches, Sunglasses , Perfumes</p>
              <a href="mailto:airport@goldsmithtci.com">gscentralsquare@gmail.com</a>
              <a href="tel:+16499415599">+1 (649) 941-5599</a>
            </div>
          </div>
          <div className="our-store-inner-card">
            <Image src={str3} />
            <div className="our-store-inner-card-info">
            <h4>The Goldsmith</h4>
              <p>International Departure Lounge, Providenciales</p>
              <p className="category-text">Jewelry ,Watches, Sunglasses , Perfumes </p>
              <a href="mailto:gscentralsquare@gmail.com">gscentralsquare@gmail.com</a>
              <a href="tel:+16499464100">+1(649)946-4100</a>
            </div>
          </div>
          <div className="our-store-inner-card">
            <Image src={str4} />
            <div className="our-store-inner-card-info">
            <h4>Caribbean Outpost Souvenir Store </h4>
              <p>Grace Bay Road, The Regent Village , Providenciales </p>
              <p className="category-text">Souvenir Store</p>
              <a href="mailto:regent@goldsmithtci.com">regent@goldsmithtci.com</a>
              <a href="tel:+16499464100">+1(649)946-4100</a>
            </div>
          </div>
          <div className="our-store-inner-card">
            <Image src={str5} />
            <div className="our-store-inner-card-info">
            <h4>Caribbean Outpost Souvenir Store</h4>
              <p>Carnival Cruise Centre ,  <br />
Grand Turk</p>
              <p className="category-text">Souvenir Store</p>
              <a href="mailto:gscentralsquare@gmail.com">gscentralsquare@gmail.com</a>
              <a href="tel:+16499464100">+1(649)946-4100</a>
            </div>
          </div>
          <div className="our-store-inner-card">
            <Image src={str6} />
            <div className="our-store-inner-card-info">
            <h4>We’ve Got Shoes</h4>
              <p>Central Square Plaza, Leeward Highway, Providenciales. </p>
              <p className="category-text">Shoes</p>
              <a href="mailto:gscentralsquare@gmail.com">gscentralsquare@gmail.com</a>
              <a href="tel:+16499464100">+1(649)946-4100</a>
            </div>
          </div>
        </div>
      </div>

      <div className="no-product-bg-grey" id="getintouch"></div>
      <div className="product-contact-us-wrapper product-contact-us-wrapper1 py-5 mb-5">
        <Container>
          <Row className="justify-content-center pb-5 mb-3">
            <ContactUsForm />
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default AboutUs;
