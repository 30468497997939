import React, { useState } from "react";
import "./style.css";
import { Button, Card, Image, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { httpDelete, httpPost } from "../../utils/api";
import defaultImage from "../../assets/images/default-placeholder.png";

function ProductCard({ data }) {
  const { REACT_APP_BASE_URL } = process.env;
  const isToken = localStorage.getItem("goldsmith_token");
  const navigate = useNavigate();
  const [isWishList, setIsWishList] = useState(data.isWishList);
  const [isHovered, setIsHovered] = useState(false);
  const [show, setShow] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLeave = () => {
    setIsHovered(false);
  };

  const handleSubmit = async (id) => {
    if (!isToken) {
      handleShow();
    } else {
      const data = {
        productId: id,
      };
      try {
        if (isWishList) {
          await httpDelete(`${REACT_APP_BASE_URL}/user/wishlist/${id}`, data);
        } else {
          await httpPost(`${REACT_APP_BASE_URL}/user/wishlist`, data);
        }
        setIsWishList(!isWishList);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleNavigate = (data) => {
    navigate(`/productDetail/${data?._id}`);
  };

  const handlePage = (e, data) => {
    e.preventDefault();
    data === "login" ? navigate("/login") : navigate("/register");
  };

  return (
    <>
      <Card className="product-card">
        <Card.Body onMouseEnter={handleHover} onMouseLeave={handleLeave}>
          <div
            className="position-relative"
            onClick={() => handleNavigate(data)}
          >
            <div className="image-container">
              <Image src={data?.barandId.brandLogo} className="brand_logo" />
              <Card.Img
                variant="top"
                className={`product-img ${isHovered ? "hidden" : ""}`}
                src={data?.images[0] ? data?.images[0] : defaultImage}
              />
              <Card.Img
                variant="top"
                className={`hover-img ${isHovered ? "" : "hidden"}`}
                src={data?.images[1]}
              />
            </div>
            <span className="image-tag">
              <Image src={data?.imageTag} />
            </span>
            <span className="view-product">
              <Link>View Product</Link>
            </span>
          </div>

          <Card.Text className="mt-2 descrip">{data?.productName}</Card.Text>
          <Card.Title>
            {" "}
            {data?.price && data?.price !== (undefined || "undefined")
              ? "$" + data?.price
              : "Get At Best Price"}
            <span className="heart-btn" onClick={() => handleSubmit(data._id)}>
              {isWishList ? (
                <i className="fa-solid fa-heart text-danger"></i>
              ) : (
                <i className="fa-regular fa-heart"></i>
              )}
            </span>
          </Card.Title>
          <Card.Text className="mt-3 sku-text"> {data?.mrNo ? 'SKU: ' + data?.mrNo  : ""}</Card.Text>
        </Card.Body>
      </Card>

      <Modal
        show={show}
        centered
        size="lg"
        onHide={handleClose}
        className="signup-modal"
      >
        <button
          className="btn-close"
          onClick={handleClose}
          closebutton="true"
        ></button>
        <div className="signUp row my-5 py-5">
          <div className="container">
            <form>
              <div className="row px-5 no-product-wrapper justify-content-center">
                <div className="row-head mb-3 pb-3">
                  <h2 className="text-center">
                    to add this product into your Wishlist please login first!
                  </h2>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="content-btn register-btn mt-3"
                    onClick={(e) => handlePage(e, "login")}
                  >
                    LOGIN{" "}
                    <span className="arrowUpRight mx-2">
                      <i className="fas fa-arrow-up"></i>
                    </span>
                  </Button>
                </div>

                <div className="text-center mt-5 d-flex flex-wrap align-items-center justify-content-center">
                  Not Registered Yet?
                  <Link
                    onClick={(e) => handlePage(e, "signup")}
                    className="loginn_Btn"
                  >
                    Create an account
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductCard;
