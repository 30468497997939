import React, { createContext, useContext, useState } from 'react';

const ProductDataContext = createContext();

export function useProductData() {
  return useContext(ProductDataContext);
}

export function ProductDataProvider({ children }) {
  const [productData, setProductData] = useState([]); 

  return (
    <ProductDataContext.Provider value={{ productData, setProductData }}>
      {children}
    </ProductDataContext.Provider>
  );
}
