import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductDataProvider } from './context/ProductDataContext';
import { FilterProvider } from './context/SelectedFilterBoxContext';
import { CategoryProvider } from './context/categoryContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ProductDataProvider>
    <FilterProvider>
      <CategoryProvider>
        <App />
      </CategoryProvider>
    </FilterProvider>
  </ProductDataProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
