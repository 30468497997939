import React, { useEffect } from "react";
import "./style.css";
import { Col, Image } from "react-bootstrap";
import Slider from "react-slick";
import { useState } from "react";

function ImageMagnifier({
  src,
  width,
  height,
  magnifierHeight = 200,
  magnifieWidth = 200,
  zoomLevel = 1.5,
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  return (
    <div
      style={{
        position: "relative",
        height: height,
        width: width,
      }}
    >
      <img
        src={src}
        style={{ height: height, width: width }}
        onMouseEnter={(e) => {
          // update image size and turn-on magnifier
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          // update cursor position
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();

          // calculate cursor position on the image
          const x = e.pageX - left - window.pageXOffset;
          const y = e.pageY - top - window.pageYOffset;
          setXY([x, y]);
        }}
        onMouseLeave={() => {
          // close magnifier
          setShowMagnifier(false);
        }}
        alt={"img"}
      />

      <div
        style={{
          display: showMagnifier ? "" : "none",
          position: "absolute",

          // prevent maginier blocks the mousemove event of img
          pointerEvents: "none",
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: "1", // reduce opacity so you can verify position
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${src}')`,
          backgroundRepeat: "no-repeat",

          //calculate zoomed image size
          backgroundSize: `${imgWidth * zoomLevel}px ${
            imgHeight * zoomLevel
          }px`,

          //calculete position of zoomed image.
          backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
}

function SingleProductImages({ data }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isVertical, setIsVertical] = useState(true);
  const [index, setIndex] = useState(1);

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setIndex(index + 1);
  };

  const handleResize = () => {
    const screenWidth = window.screen.width;
    const devicePixelRatio = window.devicePixelRatio || 1;
    const effectiveWidth = screenWidth / devicePixelRatio;
    setIsVertical(effectiveWidth > 767);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const verticalSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    beforeChange: function (currentSlide, nextSlide) {},
    afterChange: function (currentSlide) {},
  };

  const horizontalSettings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <>
      <div className="single-product-wrapper flex-wrap d-flex">
        <Col lg={2} md={2} sm={12} className="sp-wrapper-lft">
          {isVertical ? (
            <Slider {...verticalSettings}>
              {data?.map((item, index) => (
                <div
                  key={index}
                  className={`vertical-carousel-item ${
                    item === selectedImage ? "selected" : ""
                  }`}
                >
                  <Image src={item} onClick={() => handleImageClick(item, index)} />
                </div>
              ))}
            </Slider>
          ) : (
            <Slider {...horizontalSettings}>
              {data?.map((item, index) => (
                <div
                  key={index}
                  className={`horizontal-carousel-item ${
                    item === selectedImage ? "selected" : ""
                  }`}
                >
                  <Image src={item} onClick={() => handleImageClick(item)} />
                </div>
              ))}
            </Slider>
          )}
        </Col>
        {isVertical && (
          <Col lg={10} md={10} sm={12} className="ps-4">
            <div className="large-preview-product-img position-relative">
              {selectedImage && <ImageMagnifier src={selectedImage} />}
              {!selectedImage && (
                <Image src={data?.length > 0 ? data[0] : null} />
              )}
              <span className="img_count">{data?.length > 0 && index + '/' + data.length}</span>
            </div>
          </Col>
        )}
      </div>
    </>
  );
}

export default SingleProductImages;
