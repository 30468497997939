import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const Cmp = props.Cmp;
    useEffect(() => {
        if (!localStorage.getItem("goldsmith_token")) {
            toast.info("You are not logged In");
            navigate("/")
        }
    }, [])
    return (
        <div>
            <Cmp />
        </div>
    )
}

export default ProtectedRoute;
