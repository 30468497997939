import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/index';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import './assets/css/index.css'
import AllProductListing from './pages/allProductListing/index';
import ScrollToTopButton from './components/scrollToTopButton';
import ProductDetailPage from './pages/productDetailPage';
import ScrollToTop from './components/scrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyAccount from './pages/myAccountPage';
import WishlistPage from './pages/wishlistPage';
import Header from './components/header';
import Footer from './components/footer';
import ForgotPassword from './pages/forgotPassword';
import VerifyOTP from './pages/verifyOTP';
import ResetPassword from './pages/resetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import SignUp from './components/signUp';
import LoginModal from './components/loginModal';
import AboutUs from './pages/aboutUs';
import Brands from './pages/brands';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsConditions from './pages/termsCondtions';

function App() {
  return (
    <>

      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/productListing/:name/:id' element={<AllProductListing />} />
          <Route path='/productDetail/:id' element={<ProductDetailPage />} />
          <Route path='/account'  element={<ProtectedRoute Cmp={MyAccount} />} />
          <Route path='/wishlist' element={<WishlistPage />} />
          <Route path='/about-us' element={<AboutUs/>}/>
          <Route path='/brands' element={<Brands/>}/>
          <Route path='/forgot-password' element={<ForgotPassword/>}/>
          <Route path='/verify-otp' element={<VerifyOTP/>}/>
          <Route path='/reset-password' element={<ResetPassword/>}/>
          <Route path='/register' element={<SignUp/>}/>
          <Route path='/login' element={<LoginModal/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/terms-and-conditions' element={<TermsConditions/>} />
          <Route path='/cookie-policy' element={<PrivacyPolicy/>} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <ScrollToTopButton />
    </>
  );
}

export default App;
