import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import HeaderDropdownContent from '../headerDropdownContent';
import HoverIcon from '../../../../assets/images/hovericon.png';
import { useFilterContext } from '../../../../context/SelectedFilterBoxContext';

function HeaderDropdown({ category }) {
  const location = useLocation();
  const { setSelectedFilters } = useFilterContext();
  const { pathname } = location;
  const navigate = useNavigate();
  const splitLocation = pathname.split('/');

  const [visibleCategories, setVisibleCategories] = useState({});

  const handleMouseEnter = (categoryId) => {
    setVisibleCategories((prevVisibleCategories) => ({
      ...prevVisibleCategories,
      [categoryId]: true
    }));
  };

  const handleMouseLeave = (categoryId) => {
    setVisibleCategories((prevVisibleCategories) => ({
      ...prevVisibleCategories,
      [categoryId]: false
    }));
  };

  const handleNavigate = (e, data) => {
    e.preventDefault();
    navigate(`/productListing/${data.name}/${data._id}`);
    setSelectedFilters({});
  }

  return (
    <>


      {category?.map((data) => (
        <div
          key={data._id}
          onMouseEnter={() => handleMouseEnter(data._id)}
          onMouseLeave={() => handleMouseLeave(data._id)}
        >
          <Link
            onClick={(e) => handleNavigate(e, data)}
            className={
              splitLocation[2] === data.name ? 'nav-link active' : 'nav-link'
            }
          >
            {data.name}
            {visibleCategories[data._id] && <Image src={HoverIcon} />}
          </Link>
          {visibleCategories[data._id] && (
            <HeaderDropdownContent
              categoryData={data}
              categoryName={data.name}
              categoryId={data._id}
            />
          )}
        </div>
      ))}
    </>
  );
}

export default HeaderDropdown;
