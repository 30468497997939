import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row, Tab, Tabs } from 'react-bootstrap';
import './style.css';
import { httpGet, httpPost } from '../../utils/api';
import ProductCard from '../../components/productCard/index';
import Loader from '../../components/loader';
import NoWishlistPage from './noWishlist';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { useCategory } from '../../context/categoryContext';

function WishlistPage() {
  const { category } = useCategory();
  const { REACT_APP_BASE_URL } = process.env;
  const isToken = localStorage.getItem('goldsmith_token');
  const [activeTab, setActiveTab] = useState('All');
  const [wishlistData, setWishlistData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllWishlistProduct();
  }, []);

  const getAllWishlistProduct = async () => {
    await httpGet(`${REACT_APP_BASE_URL}/user/wishlist`)
      .then((response) => {
        if (response?.data?.status) {
          setWishlistData(response?.data?.wishList);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSelect = async (key) => {
    if (key === 'All') {
      getAllWishlistProduct();
    }
    setIsLoading(true);
    setActiveTab(key);
    // const data = {
    //   cateID: key
    // };
    await httpGet(`${REACT_APP_BASE_URL} /user/wishlist/${key}`)
      .then((res) => {
        if (res?.data?.status) {
          setWishlistData(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSortPriceChange = (e) => {
    if (e.target.value === 'lowToHigh') {
      getLowToHighProductData();
    } else if (e.target.value === 'HighToLow') {
      getHighToLowProductData();
    }
  };

  const getLowToHighProductData = async () => {
    setIsLoading(true);
    try {
      const sortedData = wishlistData
        ?.slice()
        .sort((a, b) => a.price - b.price);
      setWishlistData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getHighToLowProductData = async (id) => {
    setIsLoading(true);
    try {
      const sortedData = wishlistData
        ?.slice()
        .sort((a, b) => b.price - a.price);
      setWishlistData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header />
      {isToken ? (
        <div className="wishlist-page my-5 py-2">
          <Container>
            <h1 className="wishlist-h1">Wish List</h1>
            <div className="wishlist-h mb-3 pb-2 d-flex justify-content-between align-items-center px-3">
              <span>
                {wishlistData && wishlistData.length > 0
                  ? `${wishlistData.length} Products`
                  : '0 Products'}
              </span>
              <div className="d-flex align-items-center sortDropdown">
                <span className="mx-2">SORT BY -</span>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => handleSortPriceChange(e)}
                >
                  <option>Pricing</option>
                  <option value="lowToHigh">Price -- Low to High</option>
                  <option value="HighToLow">Price -- High to Low</option>
                </Form.Select>
              </div>
            </div>
            <Row className="justify-content-center">
              <Tabs
                activeKey={activeTab}
                id="uncontrolled-tab-example"
                onSelect={handleSelect}
                className="mt-1 mb-3 pb-4 justify-content-center div-nav-tab border-bottom-0"
              >
                <Tab eventKey={'All'} title={'All'}>
                  <Row className="product-wrapper mb-4 pb-3 d-flex flex-wrap  col">
                    {isLoading ? (
                      <Loader />
                    ) : wishlistData.length == 0 ? (
                      <NoWishlistPage />
                    ) : (
                      wishlistData?.map((item, index) => {
                        return (
                          <>
                            <Col
                              key={item._id}
                              xs={12}
                              sm={12}
                              md={6}
                              lg={3}
                              className="mb-4"
                            >
                              <ProductCard key={item._id} data={item} />
                            </Col>
                          </>
                        );
                      })
                    )}
                  </Row>
                </Tab>
                {category?.map((data) => (
                  <Tab key={data?._id} eventKey={data?._id} title={data?.name}>
                    <Row className="product-wrapper mb-4 pb-3 d-flex flex-wrap  col">
                      {isLoading ? (
                        <Loader />
                      ) : wishlistData.length == 0 ? (
                        <NoWishlistPage />
                      ) : (
                        wishlistData?.map((item, index) => {
                          return (
                            <>
                              <Col
                                key={item._id}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={3}
                                className="mb-4"
                              >
                                <ProductCard key={item._id} data={item} />
                              </Col>
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Tab>
                ))}
              </Tabs>
            </Row>
          </Container>
        </div>
      ) : (
        <NoWishlistPage />
      )}
      <Footer />
    </>
  );
}

export default WishlistPage;
