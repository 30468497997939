// SITE_KEY = '6Le5ZBEoAAAAADm-zbhFzB3s48tpO2b6prJbINex';
// SECRET_KEY = '6Le5ZBEoAAAAAAqdaX9V-5QtTVM6Ax58B6qoyTUM';

import axios from "axios";

const token = localStorage.getItem("goldsmith_token");

export const getCommonHeaders = () => {
  const headers = token
    ? {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    : {
     Accept: "application/json" };
  return headers;
};


export const httpGet = async (url) => {
  return axios
    .get(url, { headers: getCommonHeaders() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.res;
    });
};

export const httpGetWithData = async (url, body) => {
  return axios
    .get(url, body, { headers: getCommonHeaders() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpDelete = async (url) => {
  return axios
    .delete(url, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.res;
    });
};

export const httpPost = async (url, body) => {
  return axios
    .post(url, body, { headers: getCommonHeaders() })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpPut = async (url, body) => {
  return axios
    .put(url, body, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.res;
    });
};

export const httpPostFormData = async (url, body) => {
  let commonHeaders = getCommonHeaders();
  delete commonHeaders.Accept;

  return axios
    .post(url, body, {
      headers: commonHeaders,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpPutFormData = async (url, body) => {
  let commonHeaders = getCommonHeaders();
  delete commonHeaders.Accept;

  return axios
    .put(url, body, {
      headers: commonHeaders,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const fetchData = async (url, data, setData) => {
  try {
    const response = await httpPost(url, data);
    if (response?.data?.status === true) {
      setData(response?.data?.response);
    }
  } catch (error) {
    console.log(error);
  }
};
