import React, { useEffect, useState } from 'react';
import './style.css';
import { Row, Col, Carousel, Button, Image, Container } from 'react-bootstrap';
import HomeImgLeft1 from '../../../../assets/images/homeleftimg1.png';
import HomeImgLeft2 from '../../../../assets/images/homeleftimg2.png';
import HomeImgLeft3 from '../../../../assets/images/homeleftimg3.png';
import { httpGet } from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';

function BlogStorySection() {
  const { REACT_APP_BASE_URL } = process.env;
  const navigate = useNavigate();
  const [storyData, setStoryData] = useState({
    title: '',
    text: '',
    images: []
  });

  useEffect(() => {
    getStoryData();
  }, []);

  const getStoryData = async () => {
    await httpGet(`${REACT_APP_BASE_URL}/user/home/story`)
      .then((response) => {
        if (response?.data?.status) {
          const responseData = response?.data?.data;
          setStoryData({
            title: responseData.title,
            description: responseData.description,
            subTitle : responseData?.subTitle,
            images: responseData.images
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let text = `welcome to Goldsmith, your premier destination for exquisite luxury jewelry and watches. With over 30 years of unparalleled excellence, we are proud to be the oldest and leading authority in luxury shopping at Turks & Caicos Island. Our commitment to quality and authenticity has made us the most trustworthy name in the industry.
    Explore our curated collection of renowned designer brands, where each piece tells a story of timeless beauty and unparalleled craftsmanship. From stunning timepieces to dazzling jewelry, we offer a range of exceptional creations that captivate and inspire.
    But our dedication to luxury doesn't stop there. Discover a world of indulgence with our additional services, including curated sunglasses, exclusive perfumes, designer footwear, elegant clothing, and a wide selection of accessories that elevate your style.
    At Goldsmith, we believe in creating unforgettable experiences for our discerning clientele. Whether you're seeking a statement piece or a treasured gift, our knowledgeable team is here to guide you every step of the way. Trust in our legacy of excellence and embark on a journey where luxury knows no bounds.`;

  return (
    <>
      <div className="home-info-wrapper position-relative">
        <Container>
          <Col className="my-4 py-5 d-flex flex-wrap home-info">
            <Col className="home-info-col" lg={5} md={12} sm={12}>
              <Carousel className="home-info-lft" fade>
                {storyData.images.length > 0 &&
                  storyData.images.map((image, index) => (
                    <Carousel.Item key={index} interval={500}>
                      <Image
                        className="w-100 homeimglft"
                        src={image}
                        alt={`Image ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                {storyData.images.length === 0 && (
                  <>
                    <Carousel.Item>
                      <Image className="w-100 homeimglft" src={HomeImgLeft1} />
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image className="w-100 homeimglft" src={HomeImgLeft2} />
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image className="w-100 homeimglft" src={HomeImgLeft3} />
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image className="w-100 homeimglft" src={HomeImgLeft1} />
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image className="w-100 homeimglft" src={HomeImgLeft2} />
                    </Carousel.Item>
                  </>
                )}
              </Carousel>
            </Col>
            <Col lg={7} md={12} sm={12}>
              <Row className="text-center justify-content-center pt-3 homeRightInfo px-4">
                <h2 className="w-100 mb-3 pb-2">{storyData?.title}</h2>
                <h6 className='text-uppercase'>{storyData?.subTitle}</h6>
                <p>
                  {window.innerWidth < 580
                    ? storyData?.description?.slice(0, 210) + '...'
                    : storyData?.description}
                </p>

                <div className="w-100 flex-wrap justify-content-center d-flex">
                  <Button
                    className="my-3 content-btn"
                    type="button"
                    onClick={() => navigate('/about-us')}
                  >
                    Read our story{' '}
                    <span className="arrowUpRight mx-2">
                      <i className="fas fa-arrow-up"></i>
                    </span>
                  </Button>
                </div>

                <ul className="row justify-content-center mt-2 pt-3 homeRightSocialIcons">
                  <li className="fb-btn">
                    {' '}
                    <a
                      href="https://www.facebook.com/GoldsmithTCI?mibextid=ZbWKwL"
                      target="blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>{' '}
                  </li>
                  <li className="wtsp-btn">
                    {' '}
                    <a href="https://wa.me/+16493319993" target="_blank">
                      {' '}
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>{' '}
                  </li>
                  <li className="yt-btn">
                    {' '}
                    <a href="">
                      {' '}
                      <i className="fa-brands fa-youtube"></i>
                    </a>{' '}
                  </li>
                  <li className="insta-btn">
                    {' '}
                    <a
                      href="https://www.instagram.com/goldsmith_tci1994/?igsh=dmJ0d3Y5cDNhNXh6"
                      target="blank"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>{' '}
                  </li>
                </ul>
              </Row>
            </Col>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default BlogStorySection;
