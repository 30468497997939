// import React, { useCallback, useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types';
// import {Form} from 'react-bootstrap';
// import './style.css';

// const MultiRangeSlider = ({ min, max, onChange }) => {
//   return (
//     <Form.Range />
//     // <div className="container">
//     //   <input
//     //     type="range"
//     //     // min={min}
//     //     // max={max}
//     //     value={min / 100}
//     //     name="minPrice"
//     //     onChange={onChange}
//     //     className="thumb thumb--left"
//     //     // style={{ zIndex: minVal > max - 100 && "5" }}
//     //   />
//     //   <input
//     //     type="range"
//     //     // min={min}
//     //     // max={max}
//     //     name="price"
//     //     value={max / 100}
//     //     onChange={onChange}
//     //     // onChange={(event) => {
//     //     //   const value = Math.max(Number(event.target.value), minVal + 1);
//     //     //   setMaxVal(value);
//     //     //   maxValRef.current = value;
//     //     // }}
//     //     className="thumb thumb--right"
//     //   />

//     //   <div className="slider priceSlider">
//     //     <div className="slider__track" />
//     //     {/* <div ref={range} className="slider__range" /> */}
//     //     <div className="slider__left-value">${min}</div>
//     //     <div className="slider__right-value">${max}</div>
//     //   </div>
//     // </div>
//   );
// };

// MultiRangeSlider.propTypes = {
//   min: PropTypes.number.isRequired,
//   max: PropTypes.number.isRequired,
//   onChange: PropTypes.func.isRequired
// };

// export default MultiRangeSlider;




import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./style.css";

const MultiRangeSlider = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  const handleMinPrice = (e) => {
    const result = Math.floor(e.target.value / 100) * 100;
    const value = Math.min(result, maxVal);
    if(value){
      setMinVal(value);
      minValRef.current = value;
    }
  }
  const handleMaxPrice = (e) => {
    const result = Math.floor(e.target.value / 100) * 100;
    const value = Math.max(result, minVal);
    if(value <= 10000 && value > 100){
      setMaxVal(value);
      maxValRef.current = value;
    }
  }
  return (
    <div className="slider-container">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const result = Math.floor(event.target.value / 100) * 100;
          // const value = Math.min(result, minVal);
          const value = Math.min(result, maxVal);
          if(value < maxVal){
            setMinVal(value);
            minValRef.current = value;
          }
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && "5" }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const result = Math.floor(event.target.value / 100) * 100;
          const value = Math.max(result, minVal);
          console.log("value: ", value);
          if(value > minVal){
            setMaxVal(value);
            maxValRef.current = value;
          }
          
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value"><input step="100" onChange={(e) => handleMinPrice(e)} type="number" value={minVal}/></div>
        <div className="slider__right-value"><input step="100" onChange={(e) => handleMaxPrice(e)} type="number" value={maxVal} /></div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MultiRangeSlider;
