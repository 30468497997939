import React, { useEffect, useState } from 'react';
import './style.css';
import Container from 'react-bootstrap/esm/Container';
import { httpGet } from '../../utils/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/SelectedFilterBoxContext';
import banner1 from '../../assets/images/banner1.webp';
import banner2 from '../../assets/images/banner2.webp';
import banner3 from '../../assets/images/banner3.webp';

function ProductBanner() {
  const { REACT_APP_BASE_URL } = process.env;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const { setSelectedFilters } = useFilterContext();
  const navigate = useNavigate();


  let barandData = location?.state?.barandData;
  let barandName = location?.state?.barandName;

  const [category, setCategory] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const res = await httpGet(`${REACT_APP_BASE_URL}/user/category_with_brands`);
      setCategory(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  function titleCase(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  const getCategoryBanner = (categoryName) => {
    switch (categoryName) {
      case 'Jewellery':
        return banner1;
      case 'Watches':
        return banner2;
      case 'sunglasses':
        return banner3;
      default:
        return banner1;
    }
  };

  const redirect = (e, data) => {
    e.preventDefault();
    navigate(`/productListing/${data.name}/${data._id}`);
    setSelectedFilters({});
  };

  return (
    <>
      <div
        className="product-banner"
        style={{
          backgroundImage: `url(${getCategoryBanner(splitLocation[2])})`
        }}
      >
        <Container fluid className="d-flex">
          {category?.map((data) => (
            <Link
              key={data._id}
              onClick={(e) => redirect(e, data)}
              className={
                splitLocation[2] === data.name ? 'nav-link active' : 'nav-link'
              }
            >
              {titleCase(data.name)}
            </Link>
          ))}
          <Link
            to={`/productListing/products/allProduct`}
            className={
              splitLocation[2] === 'products' ? 'nav-link active' : 'nav-link'
            }
          >
            {titleCase('all')}
          </Link>
        </Container>

        {(location?.state?.data?.brandName === "Goldsmith" || barandData) && (
          <div className="goldsmith-special">gOLdsmith special edition</div>
        )}
      </div>
    </>
  );
}

export default ProductBanner;
