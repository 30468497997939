import React, { useState } from 'react';
import './style.css';
import { Form, Button, Alert, Image } from 'react-bootstrap';
import { httpPost } from '../../utils/api';
import { GOOGLE_CLIENT_ID } from '../../config/endPoint';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../../components/google';
import forgotImg from '../../assets/images/forgotleft.png';
import logo from '../../assets/images/goldlogo.png';

function ForgotPassword() {
  const { REACT_APP_BASE_URL } = process.env;
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError('Email is required');
      return;
    }

    try {
      const response = await httpPost(
        `${REACT_APP_BASE_URL}/auth/forgot_password`,
        { email: email }
      );
      console.log(response);
      if (response?.data?.status) {
        localStorage.setItem('email', email);
        toast.success('Password reset instructions sent to your email.');
        navigate('/verify-otp');
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="forgot-page-outer">
        <div className="d-flex">
          <div className="col-lg-7">
            <div className="sign-up-left forgot-left d-flex flex-wrap align-items-center ps-4">
              <Image src={forgotImg} />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="signUp px-5 py-5">
              <Button onClick={handleClose} className="close-btnn">
                <i className="fa-solid fa-xmark"></i>
              </Button>
              <div className="container">
                <form onSubmit={handleFormSubmit}>
                  <div className="row justify-content-center">
                  <h1 className="text-center w-100 mt-3 responsive_logo">
                     <Link to={'/'}><Image src={logo} /></Link> 
                    </h1>
                    <h2 className="text-center mb-3 py-3">Forgot Password?</h2>
                    <div className="row flex-column align-items-center ">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email Address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Group>
                        {error && <Alert variant="danger">{error}</Alert>}{' '}
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="content-btn register-btn mt-4"
                    >
                      SEND RESET CODE{' '}
                      <span className="arrowUpRight mx-2">
                        <i className="fas fa-arrow-up"></i>
                      </span>
                    </Button>
                    <div className="signup-social-login py-5">
                      <div className="signup-text position-relative">
                        <span>or sign in with</span>
                      </div>
                      <div className="text-center socialImg mt-3 d-flex justify-content-center">
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                          <Google />
                        </GoogleOAuthProvider>
                      </div>
                    </div>
                    <div className="text-center forgot-txt d-flex flex-wrap align-items-center justify-content-center">
                      Not Registered Yet?
                      <Link to={'/register'}>Create an Account</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
