import React, { useState, useEffect, useRef } from 'react';
import './style.css'; // Create this CSS file for styling

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // const handleScroll = () => {
  //   const scrollTop = window.pageYOffset;
  //   console.log('window.scrollY : ', scrollTop);
  //   // if (window.scrollY > 300) {
  //   //   setIsVisible(true);
  //   // } else {
  //   //   setIsVisible(false);
  //   // }
  //     setIsVisible(scrollTop > 100); 
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      }
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 1000); // Change 2000 to adjust the delay in milliseconds
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutIdRef);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div
      className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
      onClick={scrollToTop}
    ></div>
  );
};

export default ScrollToTopButton;
