export const shapes = [
    "Marquise",
    "Oval",
    "Round",
    "Pear",
    "Emerald",
    "Princess",
    "Cushion",
    "Heart",
    "Asher",
    "Other"
];
export const jewelery_diamond_color = ['black', 'white', 'brown', 'other'];
export const jewelryStones = [
    "Azuline",
    "Amethyst",
    "Diamond",
    "Emerald",
    "Ruby",
    "Sapphire",
    "Pearl",
    "Opal",
    "Topaz",
    "Turquoise",
    "Garnet",
    "Citrine",
    "Aquamarine",
    "Peridot",
    "Onyx",
    "Jade",
    "Lapis Lazuli",
    "Moonstone",
    "Agate",
    "Rose Quartz",
    "Tiger's Eye",
    "Amber",
    "Coral",
    "Quartz Crystal",
    "Malachite",
    "Hematite",
    "Aventurine",
    "Kunzite",
    "Rhodonite",
    "Zircon"
];

export const sizeRanges = [
    { name: "21mm - 23mm", value: "21mm - 23mm" },
    { name: "24mm - 26mm", value: "24mm - 26mm" },
    { name: "27mm - 29mm", value: "27mm - 29mm" },
    { name: "30mm - 32mm", value: "30mm - 32mm" },
    { name: "33mm - 35mm", value: "33mm - 35mm" },
    { name: "36mm - 38mm", value: "36mm - 38mm" },
    { name: "39mm - 41mm", value: "39mm - 41mm" },
    { name: "42mm - 44mm", value: "42mm - 44mm" },
    { name: "45mm - 46mm", value: "45mm - 46mm" }
  ];
  

export const caseMaterials = [
    { name: "Stainless Steel", value: "Stainless Steel" },
    { name: "Bronze", value: "Bronze" },
    { name: "Yellow Gold", value: "Yellow Gold" },
    { name: "Rose Gold", value: "Rose Gold" },
    { name: "Mother of Pearl", value: "Mother of Pearl" },
    { name: "Titanium", value: "Titanium" },
    { name: "Ceramic", value: "Ceramic" },
    { name: "Ceramos", value: "Ceramos" },
    { name: "Metal", value: "Metal" },
    { name: "Other", value: "Other" }
  ];
  


export const watchWaterResistance = [
    { name: "10m - 200m", value: "10m - 200m" },
    { name: "200m - 400m", value: "200m - 400m" },
    { name: "400m - 600m", value: "400m - 600m" },
    { name: "600m - 800m", value: "600m - 800m" },
    { name: "900m - 1200m", value: "900m - 1200m" },
    { name: "1200m - 1400m", value: "1200m - 1400m" },
    { name: "1400m - 1600m", value: "1400m - 1600m" },
    { name: "1600m - 1800m", value: "1600m - 1800m" },
    { name: "1800m - 2000m", value: "1800m - 2000m" }
  ];
  

export const watchMovements = [
    { name: "Manual Winding", value: "Manual Winding" },
    { name: "Automatic", value: "Automatic" },
    { name: "Quartz", value: "Quartz" },
    { name: "Eco Drive", value: "Eco Drive" }
  ];
  

export const allCategories = [
    {
        id: "64f1a45128c37dddfa1f5740",
        name: 'Jewelry'
    },
    {
        id: "64f1a45e28c37dddfa1f5743",
        name: 'Watches'
    },
    {
        id: "64f1a46c28c37dddfa1f5746",
        name: 'sunglasses'
    }
]


export const FRAME_STYLE = [
    {
        name: 'Aviator',
        value: 'Aviator'
    },
    {
        name: 'Cat Eye',
        value: 'Cat Eye'
    },
    {
        name: 'Classic',
        value: 'Classic'
    },
    {
        name: 'Fashion',
        value: 'Fashion'
    },
    {
        name: 'Luxury',
        value: 'Luxury'
    },
    {
        name: 'Rectangular',
        value: 'Rectangular'
    },
    {
        name: 'Rimless',
        value: 'Rimless'
    },
    {
        name: 'Shield',
        value: 'Shield'
    },
    {
        name: 'Wrap',
        value: 'Wrap'
    },
    {
        name: 'Other',
        value: 'Other'
    },
]

export const FRAME_COLOR = [
    { name: 'Black', value: 'Black' },
    { name: 'Blue', value: 'Blue' },
    { name: 'Brown', value: 'Brown' },
    { name: 'Gold', value: 'Gold' },
    { name: 'Green', value: 'Green' },
    { name: 'Grey', value: 'Grey' },
    { name: 'Pink', value: 'Pink' },
    { name: 'Silver', value: 'Silver' },
    { name: 'Tortoise', value: 'Tortoise' },
    { name: 'Transparent', value: 'Transparent' },
    { name: 'White', value: 'White' },
    { name: 'Other', value: 'Other' },
];


export const LENS_COLOR = [
    { name: 'Neutral Grey', value: 'Neutral Grey' },
    { name: 'Blue Grey', value: 'Blue Grey' },
    { name: 'Copper Grey', value: 'Copper Grey' },
    { name: 'Copper Silver', value: 'Copper Silver' },
    { name: 'Gray', value: 'Gray' },
    { name: 'Gray Silver', value: 'Gray Silver' },
    { name: 'Green', value: 'Green' },
    { name: 'Pink Grey', value: 'Pink Grey' },
    { name: 'Sunrise Silver', value: 'Sunrise Silver' },
    { name: 'Other', value: 'Other' },
]



export const LENS_MATERIAL = [
    { name: "Lightwave Glass", value: "Lightwave Glass" },
    { name: "Polycarbonate", value: "Polycarbonate" },
    { name: "Other", value: "Other" }
];

export const FRAME_FIT = [
    { name: "Small", value: "Small" },
    { name: "Medium", value: "Medium" },
    { name: "Medium - Large", value: "Medium - Large" },
    { name: "Large", value: "Large" },
    { name: "Extra Large", value: "Extra Large" },
    { name: "Other", value: "Other" }
];

export const FRAME_MATERIAL = [
    { name: "Acetate", value: "Acetate" },
    // { name: "Innovative", value: "Innovative" },
    { name: "Metal", value: "Metal" },
    // { name: "Titaniums", value: "Titaniums" },
    // { name: "Nylon&Propionate", value: "Nylon&Propionate" },
    { name: "Other", value: "Other" }
];

export const frameColors = [
    { name: 'Black', value: 'Black' },
    { name: 'Blue', value: 'Blue' },
    { name: 'Brown', value: 'Brown' },
    { name: 'Gold', value: 'Gold' },
    { name: 'Grey', value: 'Grey' },
    { name: 'Clear', value: 'Clear' },
    { name: 'Green', value: 'Green' },
    { name: 'Yellow', value: 'Yellow' },
    { name: 'Gray', value: 'Gray' },
    { name: 'Metallic', value: 'Metallic' },
    { name: 'Pink', value: 'Pink' },
    { name: 'Red', value: 'Red' },
    { name: 'Purple', value: 'Purple' },
    { name: 'Silver', value: 'Silver' },
    { name: 'Orange', value: 'Orange' },
    { name: 'White', value: 'White' },
    { name: 'Tortoise', value: 'Tortoise' },
    { name: 'Black Horn/Blue', value: 'Black Horn/Blue' },
    { name: 'Transparent', value: 'Transparent' },
    { name: 'Blonde Havana', value: 'Blonde Havana' },
    { name: 'Dark Havana', value: 'Dark Havana' },
    { name: 'Deep Gold', value: 'Deep Gold' },
    { name: 'Gradient Havana', value: 'Gradient Havana' },
    { name: 'Havana', value: 'Havana' },
    { name: 'Mastic', value: 'Mastic' },
    { name: 'Matte Black', value: 'Matte Black' },
    { name: 'Rose Gold', value: 'Rose Gold' },
    { name: 'Shiny Black', value: 'Shiny Black' },
    { name: 'Shiny Gunmetal', value: 'Shiny Gunmetal' },
    { name: 'Shiny Light Blue', value: 'Shiny Light Blue' },
    { name: 'Shiny Light Brown', value: 'Shiny Light Brown' },
    { name: 'Other', value: 'Other' }
  ];