import React from 'react'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { httpPost } from '../../utils/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Google({ closeModal }) {
  const navigate = useNavigate()
  const {REACT_APP_BASE_URL} = process.env;

  const callGoogle = async (token) => {
    let decoded = jwt_decode(token);
    const data = {
      email: decoded.email,
      googleId: decoded.sub
    }
    await httpPost(`${REACT_APP_BASE_URL}/auth/google`, data).then((res) => {
      if (res?.data?.status) {
        localStorage.setItem('goldsmith_token', res?.data?.token)
        localStorage.setItem('userData', JSON.stringify(res?.data?.response));
        toast.success(res?.data?.message)
        navigate('/account');
        // closeModal();
      }
    })
    .catch((error) => {
      toast.error('Email already exits in database');
    })
  }

  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        callGoogle(credentialResponse.credential)
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
  )
}

export default Google;
