import React from "react";
import "./style.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Form, FloatingLabel, Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Google from "../google";
import { httpPost } from "../../utils/api";
import { GOOGLE_CLIENT_ID } from "../../config/endPoint";
import { toast } from "react-toastify";
import leftBanner from "../../assets/images/leftBanner.png";
import logo from '../../assets/images/goldlogo.png'
import moment from "moment";

function SignUp() {
  const { REACT_APP_BASE_URL } = process.env;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isVisible, setVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    phoneNumber: "",
    anniversary: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggle = () => {
    setVisible(!isVisible);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.dob) {
      newErrors.birthday = "Birthday is required";
    }

    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (formData.phoneNumber.length > 10) {
      newErrors.phoneNumber = "Phone Number must be of valid length";
    }

    if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        name: formData.name,
        email: formData.email,
        dob: moment(formData.dob).format("DD-MM-YYYY"),
        phoneNumber: formData.phoneNumber,
        anniversary: moment(formData.anniversary).format("DD-MM-YYYY"),
        password: formData.password,
      };
      await httpPost(`${REACT_APP_BASE_URL}/auth/signup`, data)
        .then((res) => {
          toast.success("Sign Up Successfully");
          navigate("/login");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="signup-page-outer signup-modal">
        <div className="d-flex">
          <div className="col-lg-7">
            <div className="sign-up-left">
              <Image src={leftBanner} />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="signUp px-5 py-3">
              <Button onClick={handleClose} className="close-btnn">
                <i className="fa-solid fa-xmark"></i>
              </Button>
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="row px-2 justify-content-center">
                    <h1 className="text-center w-100 mt-3 responsive_logo">
                     <Link to={'/'}><Image src={logo} /></Link> 
                    </h1>
                    <h2 className="text-center mb-3 py-3">Create an Account</h2>
                    <div className="signup-social-login mb-3">
                      <div className="text-center socialImg mb-3 d-flex justify-content-center">
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                          <Google />
                        </GoogleOAuthProvider>
                      </div>
                      <div className="signup-text position-relative">
                        <span>or sign up with email</span>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FloatingLabel label="Your Name" className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Your Name"
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                          />
                        </FloatingLabel>
                        {errors.name && (
                          <p className="error_message">{errors.name}</p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FloatingLabel label="Email" className="mb-3">
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={(e) => handleChange(e)}
                          />
                        </FloatingLabel>
                        {errors.email && (
                          <p className="error_message">{errors.email}</p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FloatingLabel label="Birthday" className="mb-3">
                          <Form.Control
                            type="date"
                            placeholder="Birthday"
                            name="dob"
                            value={formData.dob}
                            onChange={(e) => handleChange(e)}
                          />
                        </FloatingLabel>
                        {errors.dob && (
                          <p className="error_message">{errors.dob}</p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Form.Group className="mb-3">
                          <PhoneInput
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber} 
                            onChange={(phoneNumber) => {
                              setFormData({
                                ...formData,
                                phoneNumber,
                              });
                            }}
                          />
                        </Form.Group>
                        {errors.phoneNumber && (
                          <p className="error_message">{errors.phoneNumber}</p>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FloatingLabel
                          label="Anniversary(Optional)"
                          className="mb-3"
                        >
                          <Form.Control
                            type="date"
                            placeholder="Anniversary"
                            name="anniversary"
                            value={formData.anniversary}
                            onChange={(e) => handleChange(e)}
                          />
                        </FloatingLabel>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <FloatingLabel label="Password" className="mb-3 d-flex align-items-center">
                          <Form.Control
                          className="pass-input"
                            type={!isVisible ? "password" : "text"}
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={(e) => handleChange(e)}
                          />
                          <span className="icon" onClick={toggle}>
                            {!isVisible ? (
                              <i class="fas fa-eye"></i>
                            ) : (
                              <i class="fas fa-eye-slash"></i>
                            )}
                          </span>
                        </FloatingLabel>
                        {errors.password && (
                          <p className="error_message">{errors.password}</p>
                        )}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      className="content-btn register-btn mt-3"
                    >
                      REGISTER{" "}
                      <span className="arrowUpRight mx-2">
                        <i className="fas fa-arrow-up"></i>
                      </span>
                    </Button>

                    <div className="text-center mt-3 d-flex flex-wrap justify-content-center align-items-center">
                      Already have an account?{" "}
                      <Link to={"/login"} className="loginn_Btn">
                        LOGIN
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
