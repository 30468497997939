import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Image, Form, Container, Row } from 'react-bootstrap';
import leftImg from '../../assets/images/offerleft.png';
import './style.css';
import logo from '../../assets/images/logo.png';
import { httpGet, httpPost } from '../../utils/api';
import { toast } from 'react-toastify';

function OfferPopUp({ categoryId, isPopUpKey }) {
  const isKey = localStorage.getItem(isPopUpKey);
  const { REACT_APP_BASE_URL } = process.env;
  const [show, setShow] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [offerData, setOfferData] = useState(null);
  const [email, setEmail] = useState("")

  const handleClose = () => {
    setShow(false);
    localStorage.setItem(isPopUpKey, false);
  };

  const handleCloseSubmit = () => {
    setShowSubmit(false);
    localStorage.setItem(isPopUpKey, false);
  };

  const getOfferByCategoryId = useCallback(async (id) => {
    try {
      const response = await httpGet(`${REACT_APP_BASE_URL}/user/offer/${id}`);
      if (response?.data?.status) {
        setOfferData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [REACT_APP_BASE_URL]);

  useEffect(() => {
    if(categoryId && categoryId !== "allProduct"){
      getOfferByCategoryId(categoryId);
      if (!isKey) {
        const timeoutId = setTimeout(() => {
          setShow(true);
        }, 5000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [categoryId, getOfferByCategoryId, isKey]);

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const data = {
      email : email
    }
    await httpPost(`${REACT_APP_BASE_URL}/user/offer/grab/${offerData?._id}`, data).then((response) => {
        if(response?.data?.status){
          setShow(false);
          setShowSubmit(true)
            setEmail("");
        }
        else {
          toast.error(response?.data?.message);
        }
        
      })
    .catch((err)=>{
      console.error(err)
    })
  }

  return (
    <>
    <Modal className="offer-modal" show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <Button onClick={handleClose} className="close-btnn">
          <i className="fa-solid fa-xmark"></i>
        </Button>
        <div className="offerModalOuter">
          <div className="col-lg-6 col-md-12 col-sm-12 ofr-l">
            <Image className="offer-image" src={offerData?.image || leftImg} alt="Offer" />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 ofr-r">
            <div className="offer-right-text px-4 py-4">
              <h3>{offerData?.title}</h3>
              <h1>{offerData?.percentageText}</h1>
              <h3>{offerData?.description}</h3>
              <Form className="my-3 offer-form" onSubmit={(e) => handleSubmit(e)}> 
                <Form.Control
                  type="email"
                  placeholder="Enter your mail address"
                  className="mb-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Button className="content-btn" type="submit">
                  CLAIM OFFER
                </Button>
              </Form>
              <div className="text-center offer-text2">
                <p className="mb-1">OR</p>
                <Button className="decline-btn">Decline Offer</Button>
              </div>
              <div className="offer-logo text-center mt-4">
                <Image src={logo} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal className="offer-modal" show={showSubmit} onHide={handleCloseSubmit} size="md" centered>
      <Modal.Body>
        <Button onClick={handleClose} className="close-btnn">
          <i className="fa-solid fa-xmark"></i>
        </Button>
        <div className="offer_submit_modal">
          <Container>
            <Row className='justify-content-center'>
                <h2 className='w-100 text-center'><i class="far fa-check-circle"></i></h2>
                <h2 className='w-100 text-center'>Submitted!</h2>
                <p className='w-100 text-center mt-2'>Congratulations!! An Email has sent to you with a unique code. Please show this email to our dedicated sales team, and they'll ensure your unique discount is applied at checkout.</p>
                <h6 className='w-100 d-flex justify-content-center'><Button type='button' onClick={handleCloseSubmit} className='content-btn'>OKAY  {''} <span className="arrowUpRight mx-2">
                    <i className="fas fa-arrow-up"></i>
                  </span></Button> </h6>
            </Row>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
    </>
    
  );
}

export default OfferPopUp;
