import React from 'react';
import './style.css';
import { Card, Image } from 'react-bootstrap';
import excla from '../../assets/images/exclamation.png';
import googleIcon from '../../assets/images/google_icon.png';
import { Link } from 'react-router-dom';
import reviewbg from '../../assets/images/reviewbg.png';
import reviewbg1 from '../../assets/images/reviewbg1.png';

function ReviewCard({ data, isActive }) {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const renderStars = (rating) => {
    const numGoldStars = Math.min(5, Math.max(0, Math.round(rating))); // Ensure rating is between 0 and 5
    const goldStars = Array.from({ length: numGoldStars }, (_, index) => (
      <i key={index} className="fa-solid fa-star gold-star"></i>
    ));

    const remainingBlackStars = Array.from(
      { length: 5 - numGoldStars },
      (_, index) => (
        <i
          key={index + numGoldStars}
          className="fa-solid fa-star grey-star"
        ></i>
      )
    );

    return [...goldStars, ...remainingBlackStars];
  };

  return (
    <>
      <Card
        className={`review-card text-center ${isActive ? 'active' : ''}`}
        style={
          {
            // backgroundImage: `url(${isActive ? reviewbg1 : reviewbg})`,
          }
        }
      >
        <Card.Body className="px-5 position-relative">
          <Card.Img
            className="profile-img"
            variant="top"
            src={data?.profile_photo_url}
          />
          <Card.Title className="mt-2">{data?.author_name}</Card.Title>
          <p className="rating-star">{renderStars(data?.rating)}</p>
          <span className="d-comma">
            {' '}
            <Image src={excla} />{' '}
          </span>
          <Card.Text>{truncateText(data?.text, 200)}</Card.Text>
          <p className="review-date">{data?.date}</p>
          <div className="google-post d-flex flex-wrap align-items-center">
            <Image src={googleIcon} />
            <p className="mb-0">
              <span>Posted on</span>
              <br />
              <Link to={data?.author_url}>Google</Link>
            </p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default ReviewCard;
