import React, { useState } from "react";
import { Form, Button, Image } from "react-bootstrap";
import { httpPost } from "../../utils/api";
import "./style.css";
import { toast } from "react-toastify";
import forgotLeftImg from "../../assets/images/forgotleft.png";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/goldlogo.png";

function ResetPassword() {
  const { REACT_APP_BASE_URL } = process.env;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const email = localStorage.getItem("email");
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setError("Password and Confirm Password are required.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      const data = {
        email: email,
        new_password: password,
      };
      const response = await httpPost(
        `${REACT_APP_BASE_URL}/auth/reset_password`,
        data
      );
      if (response?.data.status) {
        toast.success("Password reset successfully");
        navigate("/login");
      } else {
        toast.error("Password reset failed");
      }
    } catch (error) {
      toast.error("Password reset failed");
    }
  };

  return (
    <>
      <div className="forgot-page-outer">
        <div className="d-flex">
          <div className="col-lg-7">
            <div className="sign-up-left forgot-left d-flex flex-wrap align-items-center ps-4">
              <Image src={forgotLeftImg} />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="signUp px-5 py-5">
              <div className="container">
                <form onSubmit={handleFormSubmit}>
                  <div className="row  justify-content-center">
                    <h1 className="text-center w-100 mt-3 responsive_logo">
                      <Link to={"/"}>
                        <Image src={logo} />
                      </Link>
                    </h1>
                    <h2 className="text-center mb-3 pb-3">Reset Password</h2>
                    <div className="row flex-column align-items-center ">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Form.Group className="mb-3" controlId="password">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <Form.Group
                          className="mb-3"
                          controlId="confirmPassword"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <Button
                      type="submit"
                      className="content-btn register-btn mt-4"
                    >
                      SUBMIT{" "}
                      <span className="arrowUpRight mx-2">
                        <i className="fas fa-arrow-up"></i>
                      </span>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
