import React, { useEffect, useState, useCallback } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import "./style.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { httpPut } from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import axios from "axios";

function MyAccount() {
  const { REACT_APP_BASE_URL } = process.env;
  const { navigate } = useNavigate();
  const token = localStorage.getItem("goldsmith_token");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    dob: "",
    phoneNumber: "",
    anniversary: "",
  });

  const [show, setShow] = useState({
    name: "",
    email: "",
  });

  const getProfile = useCallback(async () => {
    if (!token) {
      return;
    }
    const config = {
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };
    setIsLoading(true);
    await axios
      .get(`${REACT_APP_BASE_URL}/user/profile`, config)
      .then((res) => {
        if (res?.data?.status === true) {
          setIsLoading(false);
          const profileData = res?.data?.data;
          setFormData({
            name: profileData.name,
            email: profileData.email,
            dob: profileData.dob,
            phoneNumber: profileData.phoneNumber,
            anniversary: profileData.anniversary,
          });
          setShow({
            name: profileData.name,
            email: profileData.email,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.status) {
          localStorage.clear();
          toast.info("Please Log in to continue...");
          navigate("/");
        }
        console.error(error);
      });
  }, [REACT_APP_BASE_URL, navigate, token]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.phoneNumber) {
      return;
    }
    await httpPut(`${REACT_APP_BASE_URL}/user/profile`, formData)
      .then((res) => {
        if (res?.data?.status) {
          toast.success("Account Updated Successfully");
          getProfile();
        }
        localStorage.setItem("goldsmith_user", res?.data?.data?.name);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const formatDateForInput = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="my_account_outer py-5 my-3">
          <Container>
            <Row>
              <h1 className="text-center mb-4">My Account</h1>
        
              <h3 className="text-center">
                {" "}
                Welcome <b>{show.name ? show.name : "User"}</b>
              </h3>
              <h4 className="text-center">
                {show.email ? show.email : "user@gmail.com"}
              </h4>
            </Row>
            <div className="my-account-box">
              <div className="row px-5 justify-content-center my-account-box-inner">
                <h2 className="text-center mb-4 pb-3">Account Details</h2>
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <FloatingLabel label="Your Name" className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Your Name"
                          name="name"
                          value={formData.name}
                          onChange={(e) => handleChange(e)}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <FloatingLabel label="Email" className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          value={formData.email}
                          name="email"
                          onChange={(e) => handleChange(e)}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <FloatingLabel label="Birthday" className="mb-3">
                        <Form.Control
                          type="date"
                          placeholder="Birthday"
                          value={
                            formData.dob ? formatDateForInput(formData.dob) : ""
                          }
                          onChange={(e) => handleChange(e)}
                          name="dob"
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <Form.Group className="mb-3">
                        <PhoneInput
                          placeholder="Phone Number"
                          name="phoneNumber"
                          value={formData.phoneNumber} // Bind to the form data
                          onChange={(phoneNumber) => {
                            setFormData({
                              ...formData,
                              phoneNumber,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <FloatingLabel
                        label="Anniversary(Optional)"
                        className="mb-3"
                      >
                        <Form.Control
                          type="date"
                          name="anniversary"
                          value={
                            formData.anniversary
                              ? formatDateForInput(formData.anniversary)
                              : ""
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap align-items-center flex-column mt-5">
                    <div>
                      
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <Button
                      onClick={(e) => handleSubmit(e)}
                      type="button"
                      className="content-btn register-btn mt-5"
                    >
                      SAVE CHANGES{" "}
                      <span className="arrowUpRight mx-2">
                        <i className="fas fa-arrow-up"></i>
                      </span>
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Container>
        </div>
      )}

      <Footer />
    </>
  );
}

export default MyAccount;
