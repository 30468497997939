import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';
import ContactUsForm from '../contactUsForm';

function NoProductFound() {
  return (
    <>
      <div className="d-flex flex-wrap justify-content-center w-100">
        <Container>
          <div className="no-product-wrapper">
            <Row className="justify-content-center no-product-txt py-5">
              <h2 className="text-center mb-4">No product found !</h2>
              <p className="text-center w-75 mb-5 pb-5">
                No products found. Please adjust filters or try another search.{' '}
                <Link>Contact</Link> our team for assistance. Enjoy browsing our
                exclusive Collection !
              </p>
            </Row>
          </div>
        </Container>
        <div className="no-product-bg-grey" id="getintouch"></div>
        <div className="product-contact-us-wrapper product-contact-us-wrapper1 py-5 mb-5">
          <Container>
            <Row className="justify-content-center pb-5 mb-3">
              <ContactUsForm />
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default NoProductFound;
