import React from 'react';
import './style.css';

function Loader() {
  return (
    <>
      <div className="loader-outer">
        <span className="loader"></span>
      </div>
    </>
  );
}

export default Loader;
