import React, { useEffect, useState } from 'react';
import './style.css';
import { Container, Col } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import ReviewCard from '../../../../components/reviewCard/index';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function ReviewSection() {
  const { REACT_APP_BASE_URL } = process.env;
  const [activeIndex, setActiveIndex] = useState(0);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/user/review/google`
      );
      let res = await response.json();
      const filteredReviews = res?.response?.filter((data) => data.rating > 3);
      setReviews(filteredReviews);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSlideChange = (event) => {
    setActiveIndex(event.item.index);
  };

  //Owl Carousel Settings
  const optionsReview = {
    loop: true,
    center: true,
    items: 3,
    onchange: (e) => handleSlideChange(e),
    margin: 0,
    autoplay: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    dots: true,
    nav: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      767: {
        items: 2
      },
      991: {
        items: 2
      },
      1199: {
        items: 3
      }
    }
  };

  return (
    <>
      <div className="testimonials-wrapper py-5">
        <Container>
          <div className="testimonials my-5 py-3">
            <h2 className="mb-4 pb-5 testi-head">
              What Our Clients Say About Us
            </h2>
            <Col className="d-flex justify-content-between flex-wrap testi-carousel position-relative">
              {reviews && reviews.length > 0 && (
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  {...optionsReview}
                >
                  {reviews?.map((data, index) => (
                    <div
                      key={data.id}
                      className={`item review-itemm ${index === activeIndex ? 'active' : ''}`}
                    >
                      <ReviewCard
                        key={index}
                        data={data}
                        isActive={index === activeIndex}
                      />
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </Col>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ReviewSection;
