import { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const useFilterContext = () => {
  return useContext(FilterContext);
};

export const FilterProvider = ({ children }) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  const addFilter = (filterKey, filterValue, isCheckbox) => {

    let updatedSelectedFilters;
    if (isCheckbox === true) {
      updatedSelectedFilters = {
        ...selectedFilters,
        [filterKey]: [...(selectedFilters[filterKey] || []), filterValue],
      };
    }
    else {
      updatedSelectedFilters = { ...selectedFilters, [filterKey]: filterValue };
    }

    setSelectedFilters(updatedSelectedFilters);

  };


  const removeFilter = (filterKey) => {
    const { [filterKey]: deletedFilter, ...restFilters } = selectedFilters;
    setSelectedFilters(restFilters);
  };


  const addArrayFilter = (filterKey, array) => {
    setSelectedFilters({
      ...selectedFilters,
      [filterKey]: array,
    });
  };


  const removeArrayFilter = (filterKey, value) => {

    let myData = selectedFilters[filterKey];

    if (value === 'clearArray') {
      myData = [];
    } else {
      myData = myData.filter((val) => val !== value);
    }

    setSelectedFilters({ ...selectedFilters, [filterKey]: myData })

  };
  return (
    <FilterContext.Provider value={{ selectedFilters, addFilter, removeFilter, removeArrayFilter, setSelectedFilters, addArrayFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
