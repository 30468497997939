import React, { useEffect, useState } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './style.css';
import headerBanner from '../../../../assets/images/headerbanner.png';
import { fetchData, httpGet, httpPost } from '../../../../utils/api';
import { BASE_URL } from '../../../../config/endPoint';
import { watchMovements } from '../../../../constant/filterInputData';
import { useFilterContext } from '../../../../context/SelectedFilterBoxContext';

function HeaderDropdownContent({ categoryData, categoryName, categoryId }) {
  const navigate = useNavigate();
  const { setSelectedFilters } = useFilterContext();

  const handleClick = (e, value, keyName, value1, keyName1) => {
    console.log("value", value);
    e.preventDefault();
    if (value && keyName) {
      navigate(`/productListing/${categoryName}/${categoryId}`, {
        state: { data: value, keyName: keyName }
      });
    }
    if (value && keyName && value1 && keyName1) {
      navigate(`/productListing/${categoryName}/${categoryId}`, {
        state: {
          data: value,
          keyName: keyName,
          barandData: value1,
          barandName: keyName1
        }
      });
    }
    setSelectedFilters({});
  };

  useEffect(() => {}, [categoryId]);

  let goldsmithBrand = categoryData?.brands?.filter(
    (data) => data.brandName === "Goldsmith"
  );

  console.log("goldsmithBrand", goldsmithBrand)

  return (
    <>
      <div className="dropdown-container">
        <div className="dropdown-wrapper">
          <div className="dropdown-wrapper-inner pt-3">
            <Row>
              <Col lg={6} md={6}>
                <div className="dropdown-lft-cnt d-flex flex-wrap px-5">
                  {categoryName === "Jewellery" && (
                    <Col lg={6} md={6}>
                      <h3>Categories</h3>
                      <ul>
                        {goldsmithBrand[0]?.jewelleryTypes?.map(
                          (data, index) => {
                            return (
                              <li key={index}>
                                {" "}
                                <Link
                                  onClick={(e) =>
                                    handleClick(
                                      e,
                                      data,
                                      "jewellery_Type_id",
                                      data.barandId,
                                      "barandId"
                                    )
                                  }
                                >
                                  {" "}
                                  {data.jewelleryName}
                                </Link>{" "}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </Col>
                  )}

                  {categoryName === "Watches" && (
                    <Col lg={6} md={6}>
                      <h3>Movement</h3>
                      <ul>
                        {watchMovements.map((data, index) => {
                          return (
                            <li key={index}>
                              {" "}
                              <Link
                                onClick={(e) =>
                                  handleClick(e, data.value, "movement")
                                }
                              >
                                {" "}
                                {data.name}
                              </Link>{" "}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  )}

                  {categoryName === "sunglasses" && (
                    <Col lg={6} md={6}>
                      <h3>Gender</h3>
                      <ul>
                        <li>
                          <Link
                            onClick={(e) => handleClick(e, "male", "gender")}
                          >
                            Male
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            onClick={(e) => handleClick(e, "female", "gender")}
                          >
                            Female
                          </Link>{" "}
                        </li>
                        <li>
                          <Link
                            onClick={(e) => handleClick(e, "unisex", "gender")}
                          >
                            Unisex{" "}
                          </Link>{" "}
                        </li>
                      </ul>
                    </Col>
                  )}

                  <Col lg={6} md={6}>
                    <h3>Brands</h3>

                    <ul>
                      {categoryData?.brands?.slice(0, 6).map((brand) => (
                        <li key={brand._id}>
                          <Link
                            onClick={(e) => handleClick(e, brand, "barandId")}
                          >
                            {brand.brandName}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </Col>
                </div>
              </Col>
              <Col lg={6} md={6}>
                <div className="dropdown-rght-banner">
                  <Image src={headerBanner} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderDropdownContent;
