import React from 'react';
import './style.css';
import { Button, Container, Row } from 'react-bootstrap';
import LoginModal from '../../../components/loginModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NoWishlistPage() {
  const isToken = localStorage.getItem('goldsmith_token');

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/login');
  };

  return (
    <>
      <div className="no-wishlist py-5 my-5">
        <Container>
          <Row className="wishlist-not-found justify-content-center py-5">
            <h1>Your Wishlist is empty !!</h1>
            {!isToken && (
              <>
                <h5>
                  To see the items you have previously added, please log in{' '}
                </h5>
                <div className='d-flex justify-content-center'>
                <Button
                  type="button"
                  className="content-btn mt-5"
                  onClick={() => handleNavigate()}
                >
                  LOGIN{' '}
                  <span className="arrowUpRight mx-2">
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Button>
                </div>
              </>
            )}
          </Row>
        </Container>
      </div>
      <div className="no-product-bg-grey no-product-bg-grey-wishlist"></div>
    </>
  );
}

export default NoWishlistPage;
