import { React, useState } from "react";
import "./style.css";
import { Row, Col, Container, Image, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

function ContactUsForm() {
  const { REACT_APP_BASE_URL } = process.env;

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const validateForm = (formData) => {
    const errors = {};

    if (!formData.name) {
      errors.name = "Name is required";
    }

  if (!isValidPhoneNumber(formData.phoneNumber)) {
      errors.phoneNumber = "Invalid phone number format";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  // Function to validate email format
  const isValidEmail = (email) => {
    // You can use a regular expression or a more sophisticated email validation library
    // This is a simple example:
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Allow digits, spaces, dashes, and parentheses
    const phonePattern = /^[\d\s\-()+]+$/;
    return phonePattern.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(data);
    console.log("validation errors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      await axios
        .post(`${REACT_APP_BASE_URL}/user/contact/support`, data)
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            setData({
              name: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
          }
          console.log("res", res);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      <div className="contact-us-form px-5">
        <Row className="row-head pt-4 text-center flex-column align-items-center">
          <h2>Let Us Assist You</h2>
          <h6>
            From Expanding Your Collection to Finding That Perfect Piece, We're
            Here to Assist You Every Step of the Way{" "}
          </h6>
        </Row>
        <Row className="mt-4 contactUsFormWrapper .contactUsFormWrapper1">
          <Col lg={5} className="contactUsFormLft">
            <div className="d-flex mb-2 pb-3 contact_us_action_center">
              <span>
                {" "}
                <i className="fa-solid fa-phone-volume"></i>
              </span>
              <div className="px-3">
                <h4>PHONE</h4>
                <span>
                  <a href="tel:+16499415599">+1 649-941-5599</a>
                </span>{" "}
                <br />
                <span>
                  <a href="tel:+16499464226">+1 649-946-4226</a>
                </span>
              </div>
            </div>
            <div className="d-flex mb-2 pb-3 contact_us_action_center">
              <span>
                <i className="fa-solid fa-envelope"></i>
              </span>
              <div className="px-3">
                <h4>Mail</h4>
                <span>
                  <a href="mailto:regent@goldsmithtci.com">
                    regent@goldsmithtci.com
                  </a>
                </span>
              </div>
            </div>
            <div className="d-flex address-section  mb-2 pb-2">
              <span>
              <i class="fas fa-map-pin"></i>
              </span>
              <div className="px-3">
                <h4>Come Visit Us</h4>
                <address>
                  P.O.box 55 The Regent village Grace Bay, Providenciales Turks
                  & Caicos Islands.
                </address>
              </div>
            </div>
            <h3 className="w-100">
              <Link
                target="_blank"
                to={
                  "https://maps.app.goo.gl/n3Cqc7XWqgvjJGwT7"
                }
              >
                Store Locator
              </Link>{" "}
            </h3>
          </Col>
          <Col lg={7}>
            <Row>
              <Form className="text-center">
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onChange={(e) => handleChange("name", e.target.value)}
                    value={data?.name}
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    onChange={(e) => handleChange("email", e.target.value)}
                    value={data?.email}
                    required
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  {/* <Form.Control
                    type="number"
                    placeholder="Phone Number"
                    onChange={(e) =>
                      handleChange("phoneNumber", e.target.value)
                    }
                    value={data?.phoneNumber}
                  /> */}

                  <PhoneInput
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={data.phoneNumber}
                    onChange={(phoneNumber) => {
                      setData({
                        ...data,
                        phoneNumber,
                      });
                    }}
                  />

                  {/* <PhoneInput
                                                        placeholder="Phone Number"
                                                        name='phoneNumber'
                                                        value={data?.phoneNumber} // Bind to the form data
                                                        onChange={(e) => handleChange("phoneNumber", phoneNumber )}
                                                        onChange={(phoneNumber) => {
                                                            setData({
                                                                ...data,
                                                                phoneNumber,
                                                            });
                                                        }} */}
                  {/* /> */}
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    placeholder="Type a Message/ I am interested in this product"
                    as="textarea"
                    rows={3}
                    onChange={(e) => handleChange("message", e.target.value)}
                    value={data?.message}
                  />
                  {errors.message && (
                    <div className="text-danger">{errors.message}</div>
                  )}
                </Form.Group>
                <Button
                  onClick={(e) => handleSubmit(e)}
                  className="content-btn mx-auto"
                  type="button"
                >
                  Submit{" "}
                  <span className="arrowUpRight mx-2">
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Button>
              </Form>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ContactUsForm;
