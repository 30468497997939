import React from "react";
import "./style.css";
import {
  Col,
  Container,
  Row,
  Image,
  Form,
  Button,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import dutyFree from "../../assets/images/dutyfree.png";
import freeShip from "../../assets/images/freeship.png";
import offer from "../../assets/images/offer.png";
import SingleProductImages from "../../components/singleProductImages";
import { Link, useParams } from "react-router-dom";
import ContactUsForm from "../../components/contactUsForm";
import { httpGet, httpPost } from "../../utils/api";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../components/loader";
import PhoneInput from "react-phone-number-input";
import Header from "../../components/header";
import Footer from "../../components/footer";

function ProductDetailPage() {
  const { id } = useParams();
  const { REACT_APP_BASE_URL } = process.env;
  const [productData, setProductData] = useState("");
  const [isWishList, setIsWishList] = useState(productData?.isWishList);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    product_id: id,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    getProductData();
  }, [id]);

  const getProductData = async () => {
    setIsLoading(true);
    await httpGet(`${REACT_APP_BASE_URL}/user/product/${id}`)
      .then((response) => {
        setIsLoading(false);
        setProductData(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function isValidEmail(email) {
    // Regular expression for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name.trim()) {
      errors.first_name = "First name is required";
    }
    if (!formData.last_name.trim()) {
      errors.last_name = "Last name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    const data = {
      product_id: id,
    };
    try {
      if (isWishList) {
        // await httpPost(`${REACT_APP_BASE_URL}/user/removeFromWishList`, data);
      } else {
        await httpPost(`${REACT_APP_BASE_URL}/user/wishlist`, data).then(
          (res) => {
            if (!res?.data?.status) {
              toast.success(res?.data?.message);
            } else if (res?.data?.status) {
              toast.success(res?.data?.message);
            }
          }
        );
      }
      setIsWishList(!isWishList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      try {
        await httpPost(`${REACT_APP_BASE_URL}/user/inquiry/product`, formData);
        toast.success("Your product inquiry has been submitted successfully.");
        handleClose();
        setFormData({
          product_id: id,
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          message: "",
        });
      } catch (error) {
        toast.error("Failed to submit the inquiry. Please try again later.");
        console.error(error);
      }
    }
  };
  console.log(productData, "data");

  return (
    <>
      <Header />
      <div className="product-detail-wrapper pb-5">
        <Container>
          <Row className="product-detail">
            <div className="product-detail-ad my-5">
              <span>
                <Image src={dutyFree} />
                Duty Free No Tax
              </span>
              <span>
                <Image src={freeShip} />
                Free Shipping over USA
              </span>
              <span>
                <Image src={offer} />
                Exclusive Offers
              </span>
            </div>
          </Row>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Row className="mt-5 py-5 p-outer">
                <Col lg={6} md={12} sm={12} className="product-col-1">
                  <SingleProductImages data={productData?.images} />
                </Col>
                <Col lg={6} md={12} sm={12} className="product-col-2 ps-3 pe-4">
                  <div className="product-info">
                    <h3 className="text-black mb-3 pb-2">
                      {productData?.productName}
                    </h3>
                    <h6 className="text-black mb-3 pb-2">
                      {productData?.mrNo}
                    </h6>
                    <h1 className="text-black mb-5">${productData?.price}</h1>
                    <div className="button-container">
                      <Button
                        type="button"
                        onClick={handleShow}
                        className="content-btn pro-btn1"
                      >
                        more details & offers{" "}
                        <span className="arrowUpRight mx-2">
                          <i className="fas fa-arrow-up"></i>
                        </span>
                      </Button>
                      <Button
                        type="button"
                        onClick={handleSubmit}
                        className="content-btn pro-btn2"
                      >
                        Add to wishlist{" "}
                        <span className="arrowUpRight mx-2">
                          <i className="fas fa-arrow-up"></i>
                        </span>
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center discount-text my-4">
                      <Link>
                        Get big discounts on selected brands , contact us now !!
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>

      <div className="product-description-wrapper py-5">
        <Container>
          <div className="pd-wrapper-inner">
            <Row>
              <h2 className="text-center">Description</h2>
              <p className="text-center">{productData?.description}</p>
            </Row>
            {/* <Row className="pt-5 pb-5">
              <Col lg={3} md={3} sm={12}>
                <div className="pd-info">
                  <label>ITEM SPECIFICATIONS</label>
                  <label>
                    MODEL NUMBER: <span>{productData?.mrNo}</span>{" "}
                  </label>
                  <label>
                    COLLECTION: <span>SG</span>
                  </label>
                  <label>
                    {" "}
                    EARRING STYLE: <span>DROP</span>{" "}
                  </label>
                  <label>
                    {" "}
                    EARRING TYPE: <span>GEMSTONE</span>{" "}
                  </label>
                  <label>
                    {" "}
                    METAL TYPE: <span> 18K WHITE GOLD</span>{" "}
                  </label>
                  <label>
                    {" "}
                    PRODUCT TYPE: <span>EARRINGS</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE CLARITY: <span>SI2</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE COLOR: <span>H, G</span>{" "}
                  </label>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <div className="pd-info">
                  <label>ITEM SPECIFICATIONS</label>
                  <label>
                    MODEL NUMBER: <span>TE222</span>{" "}
                  </label>
                  <label>
                    COLLECTION: <span>SG</span>
                  </label>
                  <label>
                    {" "}
                    EARRING STYLE: <span>DROP</span>{" "}
                  </label>
                  <label>
                    {" "}
                    EARRING TYPE: <span>GEMSTONE</span>{" "}
                  </label>
                  <label>
                    {" "}
                    METAL TYPE: <span> 18K WHITE GOLD</span>{" "}
                  </label>
                  <label>
                    {" "}
                    PRODUCT TYPE: <span>EARRINGS</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE CLARITY: <span>SI2</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE COLOR: <span>H, G</span>{" "}
                  </label>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <div className="pd-info">
                  <label>ITEM SPECIFICATIONS</label>
                  <label>
                    MODEL NUMBER: <span>TE222</span>{" "}
                  </label>
                  <label>
                    COLLECTION: <span>SG</span>
                  </label>
                  <label>
                    {" "}
                    EARRING STYLE: <span>DROP</span>{" "}
                  </label>
                  <label>
                    {" "}
                    EARRING TYPE: <span>GEMSTONE</span>{" "}
                  </label>
                  <label>
                    {" "}
                    METAL TYPE: <span> 18K WHITE GOLD</span>{" "}
                  </label>
                  <label>
                    {" "}
                    PRODUCT TYPE: <span>EARRINGS</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE CLARITY: <span>SI2</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE COLOR: <span>H, G</span>{" "}
                  </label>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <div className="pd-info">
                  <label>ITEM SPECIFICATIONS</label>
                  <label>
                    MODEL NUMBER: <span>TE222</span>{" "}
                  </label>
                  <label>
                    COLLECTION: <span>SG</span>
                  </label>
                  <label>
                    {" "}
                    EARRING STYLE: <span>DROP</span>{" "}
                  </label>
                  <label>
                    {" "}
                    EARRING TYPE: <span>GEMSTONE</span>{" "}
                  </label>
                  <label>
                    {" "}
                    METAL TYPE: <span> 18K WHITE GOLD</span>{" "}
                  </label>
                  <label>
                    {" "}
                    PRODUCT TYPE: <span>EARRINGS</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE CLARITY: <span>SI2</span>{" "}
                  </label>
                  <label>
                    {" "}
                    SIDE DIAMONDS AVERAGE COLOR: <span>H, G</span>{" "}
                  </label>
                </div>
              </Col>
            </Row> */}
          </div>
        </Container>
      </div>

      <div className="product-contact-us-wrapper py-5">
        <Container>
          <Row className="justify-content-center pb-4">
            <h2 className="pc-h1 mb-4">Need immediate Assistance?</h2>
            <ContactUsForm />
          </Row>
        </Container>
      </div>

      <Footer />
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="signup-modal"
      >
        <button
          className="btn-close"
          onClick={handleClose}
          closebutton="true"
        ></button>
        <div className="signUp row py-5">
          <div className="container">
            <form>
              <div className="row px-5 justify-content-center">
                <div className="row-head mb-3 pb-3">
                  <h2 className="text-center">Product Inquiry</h2>
                  <h6 className="w-100 text-center">
                    Our Friendly Staff will be happy to help you with whatever
                    questions you may have about this product.
                  </h6>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <FloatingLabel label="First Name">
                      <Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        name="name"
                        value={formData.first_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            first_name: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                    {errors.first_name && (
                      <div className="error_messages">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <FloatingLabel label="Last Name">
                      <Form.Control
                        type="text"
                        placeholder="Enter Last Name"
                        name="name"
                        value={formData.last_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            last_name: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                    {errors.last_name && (
                      <div className="error_messages">{errors.last_name}</div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <FloatingLabel label="Email">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                    </FloatingLabel>
                    {errors.email && (
                      <div className="error_messages">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Form.Group className="mb-3">
                      <PhoneInput
                        placeholder="Phone Number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={(phone_number) =>
                          setFormData({ ...formData, phone_number })
                        }
                      />
                      {errors.phone_number && (
                        <div className="error_messages">
                          {errors.phone_number}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        placeholder="Type a Message/ I am interested in this product"
                        as="textarea"
                        rows={3}
                        value={formData.message}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      />
                      {errors.message && (
                        <div className="error_messages">{errors.message}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                    <Button
                      onClick={handleFormSubmit}
                      className="content-btn register-btn mt-3 btn btn-primary"
                      type="button"
                    >
                      Submit Inquiry
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductDetailPage;
