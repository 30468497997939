import React from 'react';
import './style.css';
import { Row, Container, Col, Tab, Tabs } from 'react-bootstrap';
import BrandCardOverlay from '../../../../components/brandCardOverlay';
import { useCategory } from '../../../../context/categoryContext';

function AllBrandsSection() {
  const { category } = useCategory();

  return (
    <>
      <div className="all-brands-wrapper">
        <Container>
          <Col className="all-brands mb-4 py-5 row">
            <div className="all-brands-inner">
              <Row className="row-head text-center flex-column align-items-center">
                <h2>Top Brands</h2>
                <h6>
                  Discover the epitome of luxury at Goldsmith, your trusted
                  authorized luxury retailer. Explore our curated collection of
                  authentic craftsmanship and exquisite designs from exclusive
                  luxury brands.
                </h6>
              </Row>
              <Row className="w-75 all-brands-tab-outer mx-auto justify-content-center">
                <Tabs
                  id="uncontrolled-tab-example"
                  className="mt-1 mb-3 pb-4 justify-content-center div-nav-tab border-bottom-0"
                >
                  {category?.map((item) => (
                    <Tab key={item._id} eventKey={item?._id} title={item?.name}>
                      <Row className="brand-card-outer-wrapper">
                        {item?.brands?.slice(0, 4).map((data, index) => {
                          return (
                            <>
                              <Col key={index} lg={3} md={6} sm={6}>
                                <BrandCardOverlay category={item} data={data} />
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </Tab>
                  ))}
                </Tabs>
              </Row>
            </div>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default AllBrandsSection;
