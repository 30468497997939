import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { httpGet } from '../../../../utils/api';
import { watchMovements } from '../../../../constant/filterInputData';
import { useFilterContext } from '../../../../context/SelectedFilterBoxContext';

function PhoneSideBar({ isOpen, closeSidebar, category }) {
  const navigate = useNavigate();
  const { REACT_APP_BASE_URL } = process.env;
  const {setSelectedFilters} = useFilterContext();

  const isToken = localStorage.getItem('goldsmith_token');
  const userName = localStorage.getItem('goldsmith_user');

  const [isOpenAccordian, setIsOpenAccordian] = useState({});
  const [brandData, setBrandData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const toggleAccordion = (category) => {
    const categoryId = category._id;
    setCategoryName(category?.name);
    setCategoryId(categoryId);
    getBrandsDataByCateId(category._id);
    setIsOpenAccordian((prevIsOpenAccordian) => ({
      ...prevIsOpenAccordian,
      [categoryId]: !prevIsOpenAccordian[categoryId]
    }));
  };

  const getBrandsDataByCateId = async (id) => {
    try {
      setIsLoading(true);
      const url = `${REACT_APP_BASE_URL}/user/category/${id}/brands`;

      const response = await httpGet(url);
      if (response?.data?.status) {
        setBrandData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (e, value, keyName, value1, keyName1) => {
    e.preventDefault();
    if (value && keyName) {
      navigate(`/productListing/${categoryName}/${categoryId}`, {
        state: { data: value, keyName: keyName }
      });
    }
    if (value && keyName && value1 && keyName1) {
      navigate(`/productListing/${categoryName}/${categoryId}`, {
        state: {
          data: value,
          keyName: keyName,
          barandData: value1,
          barandName: keyName1
        }
      });
    }
    setSelectedFilters({})
    closeSidebar();
  };

  const handleNavigate = (value) => {
    navigate(`/${value}`);
    closeSidebar();
  };

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    closeSidebar();
  };

  return (
    <>
    {
      isOpen && <div className={`bg-overlay-sidebar-open ${isOpen ? 'active' : ''}`} ></div>
    }
      <div className={`menu-wrap ${isOpen ? 'active' : ''}`}>
        <nav className="menu">
          <div className="icon-list">
            <div className="d-flex justify-content-between align-items-center menu-wrappr-pro">
              {isToken ? (
                <p className="mb-0" onClick={() => handleNavigate('account')}>
                  {' '}
                  <i className="fa-user fa-regular"></i> <span>{userName}</span>{' '}
                </p>
              ) : (
                <p className="mb-0" onClick={() => handleNavigate('register')}>
                  {' '}
                  <i className="fa-user fa-regular"></i>{' '}
                  <span>login or sign up</span>{' '}
                </p>
              )}
              <button
                className="close-button"
                id="close-button"
                onClick={closeSidebar}
              >
                <i className="fa-sharp fa-solid fa-xmark"></i>
              </button>
            </div>
            <ul className="mt-4">
              {category?.map((category) => (
                <div key={category._id} className="mb-3">
                  <div
                    className="accordion-header"
                    onClick={() => toggleAccordion(category)}
                  >
                    <Form.Label>{category.name}</Form.Label>
                    <i
                      className={`fa-solid fa-chevron-${isOpenAccordian[category._id] ? 'up' : 'down'}`}
                    ></i>
                  </div>
                  {isOpenAccordian[category._id] && (
                    <div className="accordion-body">
                                           {categoryName === 'Watches' && (
                        <Col md={6} sm={6} xs={6}>
                          <h6>Movement</h6>
                          <ul>
                            {watchMovements.map((data, index) => {
                              return (
                                <li key={index}>
                                  {' '}
                                  <Link
                                    onClick={(e) =>
                                      handleClick(e, data.value, 'movement')
                                    }
                                  >
                                    {' '}
                                    {data.name}
                                  </Link>{' '}
                                </li>
                              );
                            })}
                          </ul>
                        </Col>
                      )}

                      {categoryName === 'sunglasses' && (
                        <Col md={6} sm={6} xs={6}>
                          <h6>Gender</h6>
                          <ul>
                            <li>
                              <Link
                                onClick={(e) =>
                                  handleClick(e, 'male', 'gender')
                                }
                              >
                                Male
                              </Link>{' '}
                            </li>
                            <li>
                              <Link
                                onClick={(e) =>
                                  handleClick(e, 'female', 'gender')
                                }
                              >
                                Female
                              </Link>{' '}
                            </li>
                            <li>
                              <Link
                                onClick={(e) =>
                                  handleClick(e, 'unisex', 'gender')
                                }
                              >
                                Unisex{' '}
                              </Link>{' '}
                            </li>
                          </ul>
                        </Col>
                      )}

                      <Col md={6} sm={6} xs={6}>
                        <h6>Brands</h6>
                        {category?.brands?.slice(0, 6)?.map((data) => (
                          <Link
                            key={data._id}
                            onClick={(e) => handleClick(e, data, 'barandId')}
                          >
                            {data?.brandName}
                          </Link>
                        ))}
                      </Col>
                    </div>
                  )}
                </div>
              ))}

              <div
                className="accordion-header mb-3"
                onClick={() => handleNavigate('brands')}
              >
                <Form.Label>brands</Form.Label>
              </div>
              <div
                className="accordion-header mb-3"
                onClick={() => handleNavigate('about-us')}
              >
                <Form.Label>about us</Form.Label>
              </div>
              <div
                className="accordion-header mb-3"
                onClick={() => scrollToElement("getintouch")}
              >
                <Form.Label>Get in Touch</Form.Label>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}

export default PhoneSideBar;
