import {
  Nav,
  Navbar,
  Image,
  Button,
  Container,
  Modal,
  Dropdown,
} from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import "./style.css";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HoverIcon from "../../assets/images/hovericon.png";
import bars from "../../assets/images/bars.svg";
import map from "../../assets/images/pin.svg";
import user from "../../assets/images/user.svg";
import phone from "../../assets/images/phone.svg";
import heart from "../../assets/images/heart.svg";
import heartred from "../../assets/images/heartred.svg";
import $ from "jquery";
import { httpGet } from "../../utils/api";
import SearchProduct from "../searchProduct";
import { toast } from "react-toastify";
import HeaderDropdown from "./components/headerDropdown";
import { useCategory } from "../../context/categoryContext";
import PhoneSideBar from "./components/phoneSidebar";
import OfferPopUp from "../../components/offerPopUp/index";
import ContactUsForm from "../contactUsForm";

function Header() {
  const isToken = localStorage.getItem("goldsmith_token");
  const userName = localStorage.getItem("goldsmith_user");
  const isPopUp = localStorage.getItem("isPopUp");

  const { REACT_APP_BASE_URL } = process.env;
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const splitLocation = pathname.split("/");
  const { category, updateCategory } = useCategory();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showOfferPopUp, setShowOfferPopUp] = useState(false);
  const [jeweleryId, setJeweleryId] = useState("");

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    getCategory();
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const getCategory = async () => {
    await httpGet(`${REACT_APP_BASE_URL}/user/category_with_brands`)
      .then((res) => {
        if (res?.data?.data) {
          updateCategory(res?.data?.data.sort());
          let d = res?.data?.data.find((data) => data.name === "Jewellery");
          setJeweleryId(d);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showMapModal, setShowMapModal] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleShowMapModal = () => setShowMapModal(true);
  const handleCloseMapModal = () => setShowMapModal(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const scrollToElement = (elementId) => {
    const currentPath = location.pathname;
    if (currentPath === "/") {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } else {
      handleShow();
    }
  };

  const handleMyAccount = () => {
    navigate("/account");
  };

  const handleLogout = () => {
    localStorage.clear();
    toast.success("Log Out Successfully");
    navigate("/");
  };

  const handleToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const [show11, setShow11] = useState(0);

  const texts = [
    "free shipping over usa!!",
    "duty free!!  no tax!!",
    "sunglass -  buy 1 @20% off, buy 2 @25% off , buy 3 @30% off!!",
    "valentine's Sale : up to 60% off on diamond!!",
    "up to 20% discount on designer jewelry !!",
    "up to 20% discount on designer watches !!",
    "buy birth gemstones at low prices!!",
  ];
  // const time_between_text = 2;
  // const transition_duration = 0.5;

  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     setShow11((p) => {
  //       if (p === texts.length - 1) p = -transition_duration;
  //       else p = p + transition_duration;
  //       return p;
  //     });
  //   }, time_between_text * 1000);

  //   return () => clearInterval(timerId);
  // }, []);

  const time_between_text = 5; // Increase the time between texts
  const transition_duration = 1; // Adjust the transition duration as needed

  const [showIndex, setShowIndex] = useState(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      setShowIndex((prevIndex) =>
        prevIndex === texts.length - 1 ? 0 : prevIndex + 1
      );
    }, (time_between_text + transition_duration) * 1000);

    return () => clearInterval(timerId);
  }, []);

  let getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const storedUserColor = localStorage.getItem("userColor");
  const [userColor, setUserColor] = useState(
    storedUserColor || getRandomColor()
  );

  useEffect(() => {
    localStorage.setItem("userColor", userColor);
  }, [userColor]);

  const getFirstAlphabet = () => {
    if (userName) {
      const firstAlphabet = userName.charAt(0).toUpperCase();
      return (
        <div className="user-circle" style={{ background: userColor }}>
          {firstAlphabet}
        </div>
      );
    }
    return "";
  };

  // useEffect(() => {
  //   if (!isPopUp) {
  //     const timeoutId = setTimeout(() => {
  //       setShowOfferPopUp(true);
  //     }, 5000);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, []);

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isSidebarOpen]);

  return (
    <>
      <div className="header-ad py-3 text-center">
        {/* {texts.map((t, i) => (
          <p
            key={i}
            style={{
              opacity: `${show11 === i ? 1 : 0}`,
              transitionDuration: `${time_between_text + transition_duration}s`,
            }}
          >
            {t}
          </p>
        ))} */}
        {texts.map((t, i) => (
          <p
            key={i}
            style={{
              opacity: `${showIndex === i ? 1 : 0}`,
              transitionDuration: `${transition_duration}s`,
            }}
          >
            {t}
          </p>
        ))}
      </div>

      <div className={`header-wrapper ${isSticky ? "sticky-header" : ""}`}>
        <div className="header-navbar">
          <SearchProduct />
          <Navbar expand="lg" className="bg-body-tertiary header">
            <Container>
              <Navbar.Brand href="#home">
                {" "}
                <Link to={"/"}>
                  {" "}
                  <img className="logo-img" src={logo} />
                </Link>
              </Navbar.Brand>
              <Button
                type="button"
                id="open-button"
                onClick={() => toggleSidebar()}
              >
                <Image id="open-button" src={bars} />
              </Button>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-center web-navbar-collapse"
              >
                <Nav className="header-nav-links-wrapper">
                  <HeaderDropdown category={category} />

                  <Link
                    className={
                      splitLocation[1] === "brands"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={"/brands"}
                  >
                    brands
                    {splitLocation[1] === "brands" && <Image src={HoverIcon} />}
                  </Link>
                  <Link
                    className={
                      splitLocation[1] === "about-us"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to={"/about-us"}
                  >
                    about us
                    {splitLocation[1] === "about-us" && (
                      <Image src={HoverIcon} />
                    )}
                  </Link>
                  <Link
                    className="nav-link"
                    onClick={() => scrollToElement("getintouch")}
                  >
                    get in touch
                  </Link>
                </Nav>
              </Navbar.Collapse>

              <PhoneSideBar
                isOpen={isSidebarOpen}
                closeSidebar={closeSidebar}
                category={category}
              />

              <Nav
                className="justify-content-center header-icons"
                activeKey="/home"
              >
                <Nav.Item>
                  <Nav.Link
                    href="https://maps.app.goo.gl/n3Cqc7XWqgvjJGwT7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image className="map-pin" src={map} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="tel:+1234567890">
                    <Image src={phone} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    className="nav-link"
                    to={"/wishlist"}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <Image
                      className="heart-imgg"
                      src={
                        isHovered || splitLocation[1] === "wishlist"
                          ? heartred
                          : heart
                      }
                    />
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  {isToken ? (
                    <Dropdown onToggle={handleToggle}>
                      <Dropdown.Toggle className="nav-link" id="dropdown-basic">
                        {getFirstAlphabet()}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="menu">
                        <Dropdown.Item onClick={() => handleMyAccount()}>
                          <i className="fa-solid fa-user"></i> My Account
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLogout()}>
                          <i className="fa-solid fa-right-from-bracket"></i>{" "}
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Link to="/register" className="nav-link">
                      <Image src={user} />
                    </Link>
                  )}
                </Nav.Item>
              </Nav>
            </Container>
          </Navbar>
        </div>
      </div>

      <Modal show={showMapModal} onHide={handleCloseMapModal}>
        <Modal.Header closeButton>
          <Modal.Title>Map Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title="Map Location"
            width="100%"
            height="450"
            loading="lazy"
            allowFullScreen
            src="https://maps.app.goo.gl/n3Cqc7XWqgvjJGwT7"
          ></iframe>
        </Modal.Body>
      </Modal>

      {/* {showOfferPopUp && } */}
      <OfferPopUp categoryId={jeweleryId?._id} isPopUpKey={"isHomePopUp"} />

      {/* Get in touch modal  */}
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        className="signup-modal"
      >
        <button
          className="btn-close"
          onClick={handleClose}
          closebutton="true"
        ></button>
        <div className="signUp row py-5 getInTouchModal">
          <div className="container">
            <ContactUsForm/>
            {/* <form>
              <div className="row px-5 justify-content-center">
                <div className="row-head mb-3 pb-3">
                  <h2 className="text-center">Product Inquiry</h2>
                  <h6 className="w-100 text-center">
                    Our Friendly Staff will be happy to help you with whatever
                    questions you may have about this product.
                  </h6>
                </div>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <FloatingLabel label="First Name">
                      <Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        name="name"
                        value={formData.first_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            first_name: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                    {errors.first_name && (
                      <div className="error_messages">{errors.first_name}</div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <FloatingLabel label="Last Name">
                      <Form.Control
                        type="text"
                        placeholder="Enter Last Name"
                        name="name"
                        value={formData.last_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            last_name: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                    {errors.last_name && (
                      <div className="error_messages">{errors.last_name}</div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                    <FloatingLabel label="Email">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                      />
                    </FloatingLabel>
                    {errors.email && (
                      <div className="error_messages">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Form.Group className="mb-3">
                      <PhoneInput
                        placeholder="Phone Number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={(phone_number) =>
                          setFormData({ ...formData, phone_number })
                        }
                      />
                      {errors.phone_number && (
                        <div className="error_messages">
                          {errors.phone_number}
                        </div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        placeholder="Type a Message/ I am interested in this product"
                        as="textarea"
                        rows={3}
                        value={formData.message}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      />
                      {errors.message && (
                        <div className="error_messages">{errors.message}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                    <Button
                      onClick={handleFormSubmit}
                      className="content-btn register-btn mt-3 btn btn-primary"
                      type="button"
                    >
                      Submit Inquiry
                    </Button>
                  </div>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Header;
