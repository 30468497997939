import React, { useState, useEffect } from 'react';
import './style.css';
import { Container, Image } from 'react-bootstrap';
import Slider from 'react-slick';
import { httpGet } from '../../../../utils/api';

function CollectionSection() {
  const { REACT_APP_BASE_URL } = process.env;
  const [sliderData, setSliderData] = useState([]);
  const [phoneSliderData, setPhoneSliderData] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 585);

  useEffect(() => {
    fetchSliderData();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsMobileView(window.innerWidth < 585);
  };

  const fetchSliderData = async () => {
    try {
      const response = await httpGet(
        `${REACT_APP_BASE_URL}/user/home/slider`
      );
      if (response.data?.status) {
        setSliderData(response?.data?.data.images);
        setPhoneSliderData(response?.data?.data.phonefile);
      }
    } catch (error) {
      console.error('Error fetching slider data:', error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="collection-slider-outer">
      <Container>
        <div className="collection-slider-inner-wrapper">
          {isMobileView ? (
            <Slider {...settings} className="pt-3">
              {phoneSliderData.map((image, index) => (
                <div key={index}>
                  <Image src={image} />
                </div>
              ))}
            </Slider>
          ) : (
            <Slider {...settings} className="pt-3">
              {sliderData.map((image, index) => (
                <div key={index}>
                  <Image src={image} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </Container>
    </div>
  );
}

export default CollectionSection;
