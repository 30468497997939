import { React, useEffect, useState } from 'react';
import { Row, Container, Col, Image, Form, Button } from 'react-bootstrap';
import './style.css';
import { Link } from 'react-router-dom';
import footerLogo from '../../assets/images/footerlogo.png';
import yt from '../../assets/images/yt.png';
import instagram from '../../assets/images/insta.png';
import whatsapp from '../../assets/images/wtsp.png';
import fb from '../../assets/images/fb.png';
import goldSmithLogo from '../../assets/images/goldlogo.png';
import { httpGet, httpPost } from '../../utils/api';
import { toast } from 'react-toastify';

function Footer() {
  const { REACT_APP_BASE_URL } = process.env;
  const [email, setEmail] = useState("");
  const [allBrands, setAllBrands] = useState([]);

  useEffect(() => {
    getAllBrands();
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      await httpPost(`${REACT_APP_BASE_URL}/user/news_letter/subscribe`, {
        email: email,
      }).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          setEmail("");
        } else {
          toast.error(res?.data?.message);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllBrands = async () => {
    try {
      await httpGet(`${REACT_APP_BASE_URL}/user/brands`).then((response) => {
        setAllBrands(response?.data?.data);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="footer footer-web">
        <Container>
          <Row className="py-5">
            <Col lg={2}>
              <div className="d-flex mb-2 pb-2 flex-wrap">
                <h4 className="w-100">CONTACT US</h4>
                <div>
                  <address>
                    P.O.box 55 <br />
                    The Regent village <br /> Grace Bay, Providenciales <br />{" "}
                    Turks & Caicos Islands.
                  </address>
                </div>
              </div>
              <div className="d-flex  mb-2 pb-2">
                <span>
                  {" "}
                  <i className="fa-solid fa-phone-volume"></i>
                </span>
                <div className="px-2">
                  <h5>PHONE</h5>
                  <span>
                    <a href="tel:+1 6499415599">+1 649-941-5599</a>
                  </span>
                  <br />
                  <span>
                    <a href="tel:+1 6499464226">+1 649-946-4226</a>
                  </span>
                </div>
              </div>
              <div className="d-flex  mb-2 pb-2">
                <span>
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <div className="px-2">
                  <h5>Mail</h5>
                  <span>
                    <a href="mailto:regent@goldsmithtci.com">
                      regent@goldsmithtci.com
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col lg={1} className="px-0">
              <div>
                <h4 className="w-100">TOP BRANDS</h4>
                <ul>
                  {allBrands &&
                    allBrands.slice(0, 13).map((data, index) => (
                      <li key={index}>
                        <Link to={"/brands"}>{data?.brandName}</Link>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <Row>
                <Col className="text-center mt-2 pt-4">
                  <Link to={'/'}>
                    <Image className="logo" src={footerLogo} />
                  </Link>
                  <h3 className="my-4">
                    Subscribe to our newsletter to get latest updates
                  </h3>
                  <div className=" subscribe-input">
                    <Form
                      className="d-flex flex-wrap"
                      onSubmit={handleSubscribe}
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <Button className="content-btn mx-auto" type="submit">
                        Subscribe{" "}
                        <span className="arrowUpRight mx-2">
                          <i className="fas fa-arrow-up"></i>
                        </span>
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={3}>
              <div>
                <h4>WHY GOLDSMITH</h4>
                <p className="mb-4">
                  Experience the beauty of Goldsmith in Turks and Caicos, where
                  luxury meets paradise. Explore our stunning collection of
                  jewelry, watches, and sunglasses that exude elegance and
                  style. Enjoy tax-free shopping, impeccable craftsmanship, and
                  our 30-year legacy of excellence. Discover the essence of
                  timeless beauty and make your statement with Goldsmith in
                  Turks and Caicos.
                </p>
                <ul className="footer-pages">
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/terms-and-conditions"}>Terms Of Use</Link>
                  </li>
                  <li>
                    <Link to={"/cookie-policy"}>Cookies Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="social-menu position-relative mb-5">
              <ul>
                <li>
                  <a
                    target="blank"
                    href="https://www.facebook.com/GoldsmithTCI?mibextid=ZbWKwL"
                  >
                    {" "}
                    <Image src={fb} />{" "}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://wa.me/9350061535"
                  >
                    <Image src={whatsapp} />{" "}
                  </a>
                </li>
                <li>
                  <a target="blank">
                    <Image src={yt} />{" "}
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://www.instagram.com/goldsmith_tci1994/?igsh=dmJ0d3Y5cDNhNXh6"
                  >
                    <Image src={instagram} />{' '}
                  </a>
                </li>
              </ul>
            </div>
          </Row>
        </Container>
        <Row className="footer-btm">
          <Container>
            <p>
              {" "}
              Goldsmith Luxury Since 1994, 2023 All Rights Reserved. Website
              developed and managed by{" "}
              <a
                className="ms-1"
                target="_blank"
                href="https://wa.me/9350061535" rel="noreferrer"
              >
                Mansi&Team.{" "}
              </a>
              .
            </p>
          </Container>
        </Row>
      </div>

      <div className="footer footer-mobile">
        <Container>
          <Row className="py-5 footer-mobile-row">
            <Col lg={6} md={6} sm={12}>
              <Row>
                <Col className="text-center mt-2 pt-4">
                  <Link to={'/'}>
                    <Image className="logo" src={goldSmithLogo} />
                  </Link>
                  <h3 className="my-4">
                    Subscribe to our newsletter to get latest updates
                  </h3>
                  <div className="d-flex flex-wrap subscribe-input">
                    <Form onSubmit={handleSubscribe} className="w-100">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <Button className="content-btn mx-auto" type="submit">
                        Subscribe{" "}
                        <span className="arrowUpRight mx-2">
                          <i className="fas fa-arrow-up"></i>
                        </span>
                      </Button>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="d-flex my-4 pt-5 position-relative footer-col-info">
              <Col lg={1} md={1} sm={6} className="footer-col-1">
                <div>
                  <h4 className="w-100">TOP BRANDS</h4>
                  <ul>
                    <li>Roberto Coin</li>
                    <li>Alor</li>
                    <li>Simon-G</li>
                    <li>Marahlago</li>
                    <li>Raymond Weil</li>
                    <li>Michele</li>
                    <li>Oris</li>
                    <li>Hamilton</li>
                    <li>Rado</li>
                    <li>Gucci</li>
                    <li>Parada</li>
                    <li>Tomford</li>
                    <li>Rayban</li>
                  </ul>
                </div>
              </Col>
              <Col lg={2} sm={6} className="pe-3">
                <div className="d-flex mb-2 pb-2 flex-wrap">
                  <h4 className="w-100">CONTACT US</h4>
                  <div>
                    <address>
                      P.O.box 55 <br />
                      The Regent village <br /> Grace Bay, Providenciales <br />{" "}
                      Turks & Caicos Islands.
                    </address>
                  </div>
                </div>
                <div className="d-flex  mb-2 pb-2">
                  <span>
                    {" "}
                    <i className="fa-solid fa-phone-volume"></i>
                  </span>
                  <div className="px-2">
                    <span>
                      <a href="tel:+18005464814">800 546 4814</a>
                    </span>{" "}
                    <br />
                    <span>
                      <a href="tel:+18005464814">800 546 4814</a>
                    </span>
                  </div>
                </div>
                <div className="d-flex  mb-2 pb-2">
                  <span>
                    <i className="fa-solid fa-envelope"></i>
                  </span>
                  <div className="px-2">
                    <span>
                      {/* <a href="mailto:regent@goldsmithtci.com?subject=Hello&body=How%20can%20I%20help%20you?">
                      regent@goldsmithtci.com
                    </a> */}
                      <a href="mailto:regent@goldsmithtci.com">
                        regent@goldsmithtci.com
                      </a>
                    </span>
                  </div>
                </div>
              </Col>
              <div className="social-menu position-relative mb-5">
                <ul>
                  <li>
                    <a
                      target="blank"
                      href="https://www.facebook.com/GoldsmithTCI?mibextid=ZbWKwL"
                    >
                      {" "}
                      <Image src={fb} />{" "}
                    </a>
                  </li>
                  <li>
                    <a target="blank">
                      <Image src={whatsapp} />{' '}
                    </a>
                  </li>
                  <li>
                    <a target="blank"
                    >
                      <Image src={yt} />{" "}
                    </a>
                  </li>
                  <li>
                    <a target="blank"
                      href="https://www.instagram.com/goldsmith_tci1994/?igsh=dmJ0d3Y5cDNhNXh6">
                      <Image src={instagram} />{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3}>
              <div className="text-center mt-3">
                <h4>WHY GOLDSMITH</h4>
                <p className="mb-4">
                  Experience the beauty of Goldsmith in Turks and Caicos, where
                  luxury meets paradise. Explore our stunning collection of
                  jewelry, watches, and sunglasses that exude elegance and
                  style. Enjoy tax-free shopping, impeccable craftsmanship, and
                  our 30-year legacy of excellence. Discover the essence of
                  timeless beauty and make your statement with Goldsmith in
                  Turks and Caicos.
                </p>
                <ul className="footer-pages">
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/terms-and-conditions"}>Terms Of Use</Link>
                  </li>
                  <li>
                    <Link to={"/cookie-policy"}>Cookies Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Row className="footer-btm">
          <Container>
            <p>
              {" "}
              Goldsmith Luxury Since 1994, 2023 All Rights Reserved. Website
              developed and managed by{" "}
              <a
                className="ms-1"
                target="_blank"
                href="https://wa.me/9350061535"
              >
                {" "}
                Mansi&Team.{" "}
              </a>
            </p>
          </Container>
        </Row>
      </div>
    </>
  );
}

export default Footer;
