import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container, Row, Tab, Tabs, Image } from "react-bootstrap";
import "./style.css";
import jwlBanner from "../../assets/images/brandbanner.png";
import robertoCoin from "../../assets/images/robertoCoin.png";
import alorBrand from "../../assets/images/alorbrand.png";
import tacori from "../../assets/images/tacori.png";
import goldsmith from "../../assets/images/goldsmith.png";
import marahlago from "../../assets/images/marahlago.png";
import simong from "../../assets/images/simon-g.png";
import citizen from "../../assets/images/citizen.png";
import citizenLogo from "../../assets/images/citizen logo white.png";
import fredq from "../../assets/images/fredq.png";
import fredqLogo from "../../assets/images/frederique constant logo 1.png";
import mkors from "../../assets/images/michaelkors.png";
import mkorsLogo from "../../assets/images/michele logo white 2.png";
import tissot from "../../assets/images/tissot.png";
import tissotLogo from "../../assets/images/tissot logo white 1.png";
import rayweil from "../../assets/images/raymondweil.png";
import rayweilLogo from "../../assets/images/raymond weil logo white 1.png";
import rado from "../../assets/images/rado.png";
import radoLogo from "../../assets/images/rado logo white.png";
import oris from "../../assets/images/oris.png";
import orisLogo from "../../assets/images/oris logo white.png";
import hamilton from "../../assets/images/hamilton.png";
import hamiltonLogo from "../../assets/images/hamilton logo white.png";
import michele from "../../assets/images/michele.png";
import micheleLogo from "../../assets/images/michele logo white 2.png";
import robertoCoinLogo from "../../assets/images/robertoLogo.png";
import alorBrandLogo from "../../assets/images/alor 2.png";
import simongLogo from "../../assets/images/simon g 6.png";
import tacoriLogo from "../../assets/images/tacoriLogo.png";
import goldsmithLogo from "../../assets/images/goldSmithLogo.png";
import marahlagoLogo from "../../assets/images/marahlago white 1.png";
import tomford from '../../assets/images/tomford.png';
import tomfordLogo from '../../assets/images/tomford.png';
import prada from '../../assets/images/prada.png';
import pradaLogo from '../../assets/images/prada logo white 1.png';
import maujim from '../../assets/images/maujim.png';
import maujimLogo from '../../assets/images/mauijim logo white 1.png';
import costa from '../../assets/images/costa.png';
import costaLogo from '../../assets/images/costa logo white 1.png';
import gucci from '../../assets/images/gucci.png';
import gucciLogo from '../../assets/images/gucci logo white 1.png';
import oakley from '../../assets/images/oakley.png';
import oakleyLogo from '../../assets/images/oakley logo white 1.png';
import tory from '../../assets/images/torry.png';
import toryLogo from '../../assets/images/toryburch logo white 1.png';
import ContactUsForm from "../../components/contactUsForm";
import { useCategory } from "../../context/categoryContext";
import Loader from "../../components/loader";

const Brands = () => {
  const { category } = useCategory();

  return (
    <>
      <Header />

      <div
        className="product-banner"
        style={{ backgroundImage: `url(${jwlBanner})` }}
      >
        <Container fluid className="d-flex flex-wrap">
          <h1 className="w-100 text-center">
            Your Gateway t<span>o Pr</span>emium Brands
          </h1>
        </Container>
      </div>

      <div className="brands-tabs-outer">
        <Tabs
          id="uncontrolled-tab-example"
          className="mt-1 mb-3 pb-4 justify-content-center div-nav-tab border-bottom-0"
        >
          <Tab eventKey="1" title={"Jewellery"}>
            <Container>
              <div className="all-brands-outer my-5">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={robertoCoin} />
                      <Image className="brand-logo" src={robertoCoinLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={alorBrand} />
                      <Image className="brand-logo" src={alorBrandLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={simong} />
                      <Image className="brand-logo" src={simongLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={tacori} />
                      <Image className="brand-logo" src={tacoriLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={goldsmith} />
                      <Image className="brand-logo" src={goldsmithLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={marahlago} />
                      <Image className="brand-logo" src={marahlagoLogo} />
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Tab>

          <Tab eventKey="2" title={"Watches"}>
            <Container>
              <div className="all-brands-outer my-5">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={citizen} />
                      <Image className="brand-logo" src={citizenLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={fredq} />
                      <Image className="brand-logo" src={fredqLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={mkors} />
                      <Image className="brand-logo" src={mkorsLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={tissot} />
                      <Image className="brand-logo" src={tissotLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={rayweil} />
                      <Image className="brand-logo" src={rayweilLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={rado} />
                      <Image className="brand-logo" src={radoLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={oris} />
                      <Image className="brand-logo" src={orisLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={hamilton} />
                      <Image className="brand-logo" src={hamiltonLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={michele} />
                      <Image className="brand-logo" src={micheleLogo} />
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Tab>
          <Tab eventKey="3" title={"Sunglasses"}>
            <Container>
              <div className="all-brands-outer my-5">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                      <Image className="brand-img" src={maujim} />
                      <Image className="brand-logo" src={maujimLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                    <Image className="brand-img" src={prada} />
                      <Image className="brand-logo" src={pradaLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                    <Image className="brand-img" src={costa} />
                      <Image className="brand-logo" src={costaLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                    <Image className="brand-img" src={gucci} />
                      <Image className="brand-logo" src={gucciLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                    <Image className="brand-img" src={oakley} />
                      <Image className="brand-logo" src={oakleyLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    {" "}
                    <div className="brand-img-wrapper">
                    <Image className="brand-img" src={tory} />
                      <Image className="brand-logo" src={toryLogo} />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="brand-img-wrapper">
                    <Image className="brand-img" src={tomford} />
                      <Image className="brand-logo" src={tomfordLogo} />
                    </div>
                  </div>
                
                </div>
              </div>
            </Container>
          </Tab>
        </Tabs>
      </div>

      <div className="no-product-bg-grey" id="getintouch"></div>
      <div className="product-contact-us-wrapper product-contact-us-wrapper1 py-5 mb-5">
        <Container>
          <Row className="justify-content-center pb-5 mb-3">
            <ContactUsForm />
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default Brands;
