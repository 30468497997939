import React from "react";
import "./style.css";
import { Row, Container, Button, Col, Image } from "react-bootstrap";
import a1 from "../../../../assets/images/a1.png";
import { useEffect } from "react";
import { useState } from "react";
import { httpGet } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { useCategory } from "../../../../context/categoryContext";
import { useFilterContext } from "../../../../context/SelectedFilterBoxContext";

function GoldSmithImageGallery() {
  const navigate = useNavigate();
  const { REACT_APP_BASE_URL } = process.env;
  const { category } = useCategory();
  const {setSelectedFilters} = useFilterContext();
  const [categoryData, setCategoryData] = useState();
  const [brandData, setBrandData] = useState([]);
  const [jewelryData, setJewelryData] = useState();

  useEffect(() => {
    if (category.length > 0) {
      checkCategory(category);
    }
  }, [category]);

  const checkCategory = (category) => {
    const data = category?.find((cat) => cat.name === "Jewellery");
    setCategoryData(data);
    if (data?._id) {
      getBrandsDataByCateId(data?._id);
    }
  };

  const getBrandsDataByCateId = async (id) => {
    try {
      await httpGet(`${REACT_APP_BASE_URL}/user/category/${id}/brands`).then(
        (res) => {
          const resp = res?.data?.data;
          const data = resp?.filter((brand) => brand.brandName === "Goldsmith");
          if (data[0]?._id) {
            getJewelryDataByBrandId(data[0]?._id);
          }
          setBrandData(data[0]);
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getJewelryDataByBrandId = async (brandId) => {
    try {
      await httpGet(`${REACT_APP_BASE_URL}/user/jewelry/brand/${brandId}`).then(
        (res) => {
          const resp = res?.data?.data;
          setJewelryData(resp);
          console.log("resp", resp)
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = (value, keyName, value1, keyName1) => {
    if (value && keyName) {
      navigate(`/productListing/${categoryData?.name}/${categoryData?._id}`, {
        state: { data: value, keyName: keyName },
      });
    }
    if (value && keyName && value1 && keyName1) {
      navigate(`/productListing/${categoryData?.name}/${categoryData?._id}`, {
        state: {
          data: value,
          keyName: keyName,
          barandData: value1,
          barandName: keyName1,
        },
      });
    }
    setSelectedFilters({})
  };

  return (
    <>
      <div className="collection-wrapper mt-3 pt-5 px-0">
        <Col className="d-flex flex-wrap">
          <Col lg={4} md={6} sm={12}>
            <Row className="mx-0 px-3 mb-3 collection-wrapper-text">
              <Container>
                <h3>Goldsmith's Jewelry Collection</h3>
                <p className="mb-3 pb-4">
                  Internationally Sourced Luxury. Exquisite Designs. Timeless
                  Elegance. Discover Goldsmith's Exclusive Jewelry Collection,
                  meticulously crafted with the finest gemstones from around the
                  world. Embrace the perfect fusion of tradition and
                  contemporary style.
                </p>
                <Button
                  className="content-btn"
                  type="button"
                  onClick={() => handleClick(brandData, "barandId")}
                >
                  Explore Goldsmith's{" "}
                  <span className="arrowUpRight mx-2">
                    <i className="fas fa-arrow-up"></i>
                  </span>
                </Button>
              </Container>
            </Row>
          </Col>
          {jewelryData &&
            jewelryData?.slice(0, 5)?.map((data, index) => {
              return (
                <Col
                  key={index}
                  onClick={(e) =>
                    handleClick(
                      data,
                      "jewellery_Type_id",
                      data.barandId,
                      "barandId"
                    )
                  }
                  className="collection-card"
                  lg={4}
                  md={6}
                  sm={6}
                  xs={6}
                >
                  <Image className="w-100" src={data?.image ? data?.image : a1} loading="lazy" />
                  <div className="collection-card-bottom">
                    <span>{data.jewelleryName}</span>
                    <i className="fa-solid fa-arrow-right-long"></i>
                  </div>
                </Col>
              );
            })}
        </Col>
      </div>
    </>
  );
}

export default GoldSmithImageGallery;
