import React from 'react';
import './style.css';
import { Row, Col, Container, Image } from 'react-bootstrap';
import contactLeft from '../../../../assets/images/contactLeft.png';
import ContactUsForm from '../../../../components/contactUsForm/index';

function ContactUs() {
  return (
    <>
      <Row className="contact-us" id="getintouch">
        <Container>
          <Col className="my-5 py-5 position-relative d-flex flex-wrap align-items-center contact-us-wrapper">
            <Col lg={8} md={9} sm={12}>
              <Image className="w-100 contact-us-img" src={contactLeft} />
            </Col>
            <ContactUsForm />
          </Col>
        </Container>
      </Row>
    </>
  );
}

export default ContactUs;
