import React, { useEffect, useState } from 'react';
import Header from '../../components/header/index';
import '../home/style.css';
import Container from 'react-bootstrap/esm/Container';
import { Row, Col, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/esm/Image';
import hi1 from '../../assets/images/hi1.svg';
import hi2 from '../../assets/images/hi2.svg';
import hi3 from '../../assets/images/hi3.svg';
import hi4 from '../../assets/images/hi4.svg';
import hi5 from '../../assets/images/hi5.svg';
import Footer from '../../components/footer/index';
import ContactUs from './components/contactUs/index';
import ReviewSection from './components/reviewSection/index';
import CollectionSection from './components/collectionSection/index';
import TrendProductSlider from './components/trendProductSlider/index';
import GoldSmithImageGallery from './components/goldSmithImageGallery/index';
import CategorySlider from './components/categorySlider';
import BlogStorySection from './components/blogStorySection';
import HomeBanner from './components/homeBanner';
import AllBrandsSection from './components/allBrandsSection';

function Home() {

  return (
    <>

      <Header />

      <HomeBanner />

      <BlogStorySection />

      <Row className="business-benefits mb-5">
        <Container>
          <Col className=" py-5 d-flex business-benefit-wrapper">
            <div className="d-flex flex-wrap mx-auto business-benefits-inner justify-content-between">
              <div className="benefit-card">
                <Image src={hi1} />
                <h3>Top Rated</h3>
                <p>
                  Immerse yourself in a world of luxury with our curated
                  selection of top-rated jewelry and watches.
                </p>
              </div>
              <div className="benefit-card">
                <Image src={hi2} />
                <h3>Free Shipping over USA</h3>
                <p>
                  Unlock the joy of shopping. Enjoy hassle-free shipping on our
                  stunning jewelry and watches, delivered right to your
                  doorstep.{' '}
                </p>
              </div>
              <div className="benefit-card">
                <Image src={hi3} />
                <h3>Exclusive Offers</h3>
                <p>
                  Discover unbeatable prices on our stunning jewelry and
                  watches. Embrace luxury for less with exclusive discounts.
                </p>
              </div>
              <div className="benefit-card">
                <Image src={hi4} />
                <h3>Timeless Legacy</h3>
                <p>
                  Premier retailer of luxury jewelry & timepieces for 30
                  remarkable years. we are commitment to quality and style.
                </p>
              </div>
              <div className="benefit-card">
                <Image src={hi5} />
                <h3>Duty Free No TAX</h3>
                <p>
                  Enjoy the benefits of duty-free shopping with our exclusive
                  collection. Shop tax-free. luxury without the added tax
                  burden.
                </p>
              </div>
            </div>
          </Col>
        </Container>
      </Row>

      <CategorySlider />

      <AllBrandsSection />

      <GoldSmithImageGallery />

      <TrendProductSlider />

      <CollectionSection />

      <ReviewSection />

      <ContactUs />
      <Footer />
    </>
  );
}

export default Home;
