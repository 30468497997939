import React from 'react';
import './style.css';
import { Image } from 'react-bootstrap';
import g1 from '../../assets/images/g1.png';
import { useNavigate } from 'react-router-dom';
import { useFilterContext } from '../../context/SelectedFilterBoxContext';

function BrandCardOverlay({ category, data }) {
  const navigate = useNavigate();
  const {setSelectedFilters} = useFilterContext();


  const handleNavigate = () => {
    navigate(`/productListing/${category?.name}/${category?._id}`, {
      state: { data: data, keyName: 'barandId' }
    });
    setSelectedFilters({});
  };

  return (
    <>
      <div className="brand-card-wrapper" onClick={() => handleNavigate()}>
        <div className="card-top">
          <Image className="w-100" src={data?.image ? data?.image : g1} />
        </div>
        <div className="card-bottom collection-card-bottom">
          <span>{data?.brandName}</span>
          <i className="fa-solid fa-arrow-right-long"></i>
        </div>
      </div>
    </>
  );
}

export default BrandCardOverlay;
