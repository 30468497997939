import React, { useEffect, useState } from "react";
import Header from "../../components/header/index";
import ProductBanner from "../../components/productBanner/index";
import {
  Button,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import "./style.css";
import Form from "react-bootstrap/Form";
import ProductCard from "../../components/productCard/index";
import Footer from "../../components/footer/index";
import PaginationComponent from "../../components/pagination/index";
import ProductFilterSidebar from "../../components/productFilterSidebar";
import NoProductFound from "../../components/noProductFound";
import { httpGet } from "../../utils/api";
import { useProductData } from "../../context/ProductDataContext";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { useFilterContext } from "../../context/SelectedFilterBoxContext";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/scrollToTop";
import OfferPopUp from "../../components/offerPopUp";

function AllProductListing() {
  const { REACT_APP_BASE_URL } = process.env;
  debugger
  const navigate = useNavigate();

  const location = useLocation();

  const { productData } = useProductData();

  const { selectedFilters, removeFilter, removeArrayFilter } =
    useFilterContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [allProductData, setAllProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; 
  const isToken = localStorage.getItem("goldsmith_token");
  const searchQuery = new URLSearchParams(location.search).get("search") || "";

  useEffect(() => {
    if (id !== "allProduct") {
      getProductData(id);
    } else if (id === "allProduct" && searchQuery === "") {
      getAllProductData();
    }
  }, [id]);

  useEffect(() => {
    if (searchQuery !== "") {
      getSearchProducts(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    setAllProductData(productData);
  }, [productData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getItemsForCurrentPage = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return allProductData?.slice(startIndex, endIndex);
  };

  const getAllProductData = async () => {
    setIsLoading(true);
    try {
      const response = await httpGet(`${REACT_APP_BASE_URL}/user/products`);
      if (response?.data?.status) {
        setAllProductData(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getProductData = async (id) => {
    setIsLoading(true);
  
    try {
      let response;

      if (isToken) {
        response = await httpGet(
          `${REACT_APP_BASE_URL}/user/products/${id}`
        );
      } else {
        response = await httpGet(
          `${REACT_APP_BASE_URL}/user/category/products/${id}`
        );
      }

      if (response?.data?.status) {
        setAllProductData(response?.data?.data);
        setIsLoading(false);
      } else if (response?.status === 401) {
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSearchProducts = async (value) => {
    setAllProductData([]);
    setIsLoading(true);
    
    try {
      const response = await httpGet(
        `${REACT_APP_BASE_URL}/user/product/search?product_name=${value}`
      );
      if (response?.data?.status) {
        setAllProductData(response?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSortPriceChange = (e) => {
    if (e.target.value === "lowToHigh") {
      getLowToHighProductData();
    } else if (e.target.value === "HighToLow") {
      getHighToLowProductData();
    }
  };

  const handleSortPriceChange1 = (e) => {
    if (e === "lowToHigh") {
      getLowToHighProductData();
    } else if (e === "HighToLow") {
      getHighToLowProductData();
    }
  };

  const getLowToHighProductData = async () => {
    setIsLoading(true);
    try {
      const sortedData = allProductData
        ?.slice()
        .sort((a, b) => a.price - b.price);
      setAllProductData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getHighToLowProductData = async (id) => {
    setIsLoading(true);
    try {
      const sortedData = allProductData
        ?.slice()
        .sort((a, b) => b.price - a.price);
      setAllProductData(sortedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [columns, setColumns] = useState(4); // Default number of columns

  useEffect(() => {
    // Set the number of columns based on sidebar open/close status
    setColumns(sidebarOpen ? 3 : 4);
  }, [sidebarOpen]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 575) {
        if (sidebarOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'visible';
        }
      } else {
        document.body.style.overflow = 'visible';
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [sidebarOpen]);

  return (
    <>
      <Header />
      <ProductBanner />

      <div className="position-relative d-flex">
        <div
          className={`pro bg-overlay-sidebar-open ${sidebarOpen ? "active" : ""}`}
        ></div>

        <ProductFilterSidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />

        <div id="main">
          <div className={sidebarOpen ? "all_pr_wrapper" : ""}>
            <Container>
              <Row className="my-3 py-2">
                <Col className="d-flex justify-content-between filterTop">
                  {id !== "allProduct"
                    ? !sidebarOpen && (
                        <Button
                          type="button"
                          onClick={() => setSidebarOpen(!sidebarOpen)}
                          className="filterBtn"
                        >
                          Filters <i className="fa-solid fa-sliders"></i>
                        </Button>
                      )
                    : `${allProductData.length} Products`}

                  {Object.keys(selectedFilters).length > 0 && (
                    <div className="selected-filter-outer">
                      {Object.keys(selectedFilters).length > 0 && (
                        <span className="product-count">
                          {allProductData.length + " " + "Products in -  "}{" "}
                        </span>
                      )}
                      {Object.keys(selectedFilters).map((key) => {
                        const value = selectedFilters[key]; 
                        if (Array.isArray(value)) {
                          return (
                            <li key={key} className="d-flex flex-wrap">
                              {value.map((val, index) => {
                                return (
                                  <>
                                    <span
                                      key={index}
                                      onClick={() =>
                                        removeArrayFilter(key, val)
                                      }
                                      className="selected-filter mb-2"
                                    >
                                      {val}{" "}
                                      <span
                                        className="remove-filter"
                                        onClick={() => removeFilter(key)}
                                      >
                                        &#10005;
                                      </span>
                                    </span>
                                  </>
                                );
                              })}
                            </li>
                          );
                        } else if (
                          typeof value === "object" &&
                          value !== null
                        ) {
                          return (
                            <span key={key} className="selected-filter mb-2">
                              {key == "jewellery_Type_id"
                                ? value.jewelleryName
                                : key == "barandId"
                                ? value.brandName
                                : value.metalsName}
                              <span
                                className="remove-filter"
                                onClick={() => removeFilter(key)}
                              >
                                &#10005;
                              </span>
                            </span>
                          );
                        } else {
                          return (
                            <span key={key} className="selected-filter mb-2">
                              {value}
                              <span
                                className="remove-filter"
                                onClick={() => removeFilter(key)}
                              >
                                &#10005;
                              </span>
                            </span>
                          );
                        }
                      })}
                    </div>
                  )}

                  <div className="d-flex sortDropdown">
                    <span className="mx-2 sortLabel">SORT BY -</span>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => handleSortPriceChange(e)}
                    >
                      <option>Pricing</option>
                      <option value="lowToHigh">Price -- Low to High</option>
                      <option value="HighToLow">Price -- High to Low</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col className="phone-filter-btn-wrapper">
                  <Button
                    type="button"
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    className="phone-filterBtn"
                  >
                    Filters <i className="fa-solid fa-sliders"></i>
                  </Button>
                  <DropdownButton
                    as={ButtonGroup}
                    key={"up"}
                    id={`dropdown-button-drop-up`}
                    drop={"up"}
                    variant="secondary"
                    title={`Sort By`}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => handleSortPriceChange1("lowToHigh")}
                    >
                      Price -- Low to High
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => handleSortPriceChange1("HighToLow")}
                    >
                      Price -- High to Low
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="product-wrapper-outer">
                {/* <Col className='product-wrapper mb-4 pb-3 d-flex flex-wrap justify-content-between'> */}

                {isLoading ? (
                  <Loader />
                ) : allProductData && allProductData?.length === 0 ? (
                  <>
                    <ScrollToTop />
                    <NoProductFound />
                  </>
                ) : (
                  getItemsForCurrentPage()?.map((data) => {
                    return (
                      <>
                        <Col
                          key={data._id}
                          xs={6}
                          sm={6}
                          md={6}
                          lg={12 / columns}
                          className="mb-3 pb-1"
                        >
                          <ProductCard key={data._id} data={data} />
                        </Col>
                      </>
                    );
                  })
                )}
                {/* </Col> */}
              </Row>
            </Container>
            <PaginationComponent
              currentPage={currentPage}
              totalItems={allProductData?.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <Footer />

      <OfferPopUp categoryId={id} isPopUpKey={"isCategoryPopUp"} />
    </>
  );
}

export default AllProductListing;
