import React, { useEffect, useState } from "react";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./style.css";
import ProductCard from "../../../../components/productCard/index";
import { httpGet } from "../../../../utils/api";
import { useCategory } from "../../../../context/categoryContext";
import { useNavigate } from "react-router-dom";

function TrendProductSlider() {
  const { REACT_APP_BASE_URL } = process.env;
  const navigate = useNavigate();
  const { category } = useCategory();
  const isToken = localStorage.getItem("goldsmith_token");
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState([]);
  const [activeTab, setActiveTab] = useState(category[0]?._id);

  const getProductData = async (id) => {
    setIsLoading(true);
    setActiveTab(id);
    try {
      let response;

      if (isToken) {
        response = await httpGet(`${REACT_APP_BASE_URL}/user/products/${id}`);
      } else {
        response = await httpGet(
          `${REACT_APP_BASE_URL}/user/category/products/${id}`
        );
      }

      if (response?.data?.status) {
        setProductData(response?.data?.data);
        setIsLoading(false);
      } else if (response?.status === 401) {
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (category.length > 0) {
      getProductData(category[0]?._id);
    }
  }, [category]);

  return (
    <>
      <Row className="w-100 mx-0 ">
        <Container className="mt-3 pt-5">
          <Row className="row-head text-center flex-column align-items-center">
            <h2>Latest Trends</h2>
            <h6>
              Stay ahead of the curve with our collection of trending products.
              Discover the latest styles and must-have items that are making
              waves in the world of fashion and luxury. Shop now and be the
              trendsetter.
            </h6>
          </Row>
        </Container>
        <div className="w-100 mb-4 pb-5 trend-slider-wrapper">
          <Row className="justify-content-center">
            <Tabs
              id="uncontrolled-tab-example"
              activeKey={activeTab}
              onSelect={(key) => getProductData(key)}
              className="mt-1 mb-3 pb-4 justify-content-center div-nav-tab border-bottom-0"
            >
              {category?.map((data) => (
                <Tab key={data._id} eventKey={data?._id} title={data?.name}>
                  <Container>
                    {isLoading ? (
                      <div className="w-100 text-center">Loading...</div>
                    ) : (
                      <div className="d-flex justify-content-between flex-wrap w-100">
                        <Swiper
                          className="trend-slider"
                          spaceBetween={20}
                          slidesPerView={4.3}
                          loop
                          autoplay
                          centeredSlides={true}
                          centeredSlidesBounds={true}
                          breakpoints={{
                            300: {
                              slidesPerView: 2,
                            },
                            640: {
                              width: 640,
                              slidesPerView: 3,
                            },
                            768: {
                              width: 768,
                              slidesPerView: 2,
                            },
                          }}
                        >
                          {productData?.length > 0 &&
                            productData.map((data, index) => (
                              <SwiperSlide key={index}>
                                <ProductCard data={data} />
                              </SwiperSlide>
                            ))}
                        </Swiper>
                        {productData?.length == 0 && (
                          <div className="w-100 text-center">
                            No products found
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                </Tab>
              ))}
            </Tabs>
          </Row>
        </div>
      </Row>
    </>
  );
}

export default TrendProductSlider;
