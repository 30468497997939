import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import homeBannerLogo from '../../../../assets/images/homeBannerLogo1.png';
import './style.css';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import B1 from '../../../../assets/images/b1.png';
import B2 from '../../../../assets/images/b2.png';
import B3 from '../../../../assets/images/b3.png';
import B4 from '../../../../assets/images/b4.png';
import B5 from '../../../../assets/images/b5.png';
import B6 from '../../../../assets/images/b6.png';
import B7 from '../../../../assets/images/b7.png';
import B8 from '../../../../assets/images/b8.png';

function HomeBanner() {
  return (
    <>
      <div className="home-banner d-flex align-items-center justify-content-center">
        <div className="video-container">
          <video
            disableRemotePlayback
            src="https://s3.us-east-2.amazonaws.com/goldsmithgrabay.com/goldsmithvideo.mp4"
            autoPlay
            style={{ width: '100%', height: ' 100%' }}
            loop
            muted
            className="video-background"
          />
        </div>
        <div className="blacklist-overlay"></div>

        <Container fluid>
          <div className="home-banner-inner h-100 col-12 d-flex flex-wrap justify-content-center">
            <span className="text-center position-relative">
              <img src={homeBannerLogo} alt="" />
            </span>
            <div className=" brands-logo">
              <ListGroup
                horizontal
                className="flex-wrap justify-content-center"
              >
                <ListGroup.Item>
                  <Image src={B1} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B2} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B8} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B3} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B4} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B5} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B7} />
                </ListGroup.Item>
                <ListGroup.Item>
                  <Image src={B6} />
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default HomeBanner;
