import { Accordion, Form } from "react-bootstrap";
import { useFilterContext } from "../../context/SelectedFilterBoxContext";

function CheckBoxInput({
  label,
  array,
  name,
  onChange,
  toggleAccordion,
  isOpenAccordian,
}) {
  const {
    selectedFilters,
    setSelectedFilters,
    removeArrayFilter,
    addArrayFilter,
  } = useFilterContext();

  const handleClearArray = (e, name) => {
    e.stopPropagation();
    const { checked } = e.target;
    if (checked) {
      setSelectedFilters({
        ...selectedFilters,
        name: [],
      });
      removeArrayFilter(name, "clearArray");
    }
  };

  const handleCheckArrayAll = (e, key, array) => {
    e.stopPropagation();
    const arr = array.map((data) => data.name);
    addArrayFilter(key, arr);
  };

  return (
    <>
      <div className="mb-3">
        <div className="accordion-header" onClick={() => toggleAccordion(name)}>
          <Form.Label>{label}</Form.Label>
          <i
            className={`fa-solid fa-chevron-${
              isOpenAccordian[name] ? "up" : "down"
            }`}
          ></i>
          {name === "jewellery_stone" && (
            <Form.Check
              type="checkbox"
              onClick={(e) => handleCheckArrayAll(e, "jewellery_stone", array)}
              name="checkAll"
              checked={array?.length === selectedFilters[name]?.length}
              label="check all"
            />
          )}
          {(name === "jewellery_diamonds" || name === "jewellery_stone") && (
            <Form.Check
              onClick={(e) => handleClearArray(e, name)}
              className="custom-radio"
              type="radio"
              checked={false}
              label={name === "jewellery_stone" ? "no stone" : "no diamond"}
            />
          )}
        </div>
        {isOpenAccordian[name] && (
          <div className="accordion-body">
            {array?.map((data, key) => {
              return (
                <>
                  <Form.Check
                    key={key}
                    type="checkbox"
                    name={name}
                    checked={
                      name !== "jewellery_cut" && name !== "jewellery_diamonds"
                        ? selectedFilters[name]?.includes(data.name)
                        : selectedFilters[name]?.includes(data)
                    }
                    // checked={(name === 'jewellery_stone') ? selectedFilters[name]?.includes(data.name) : selectedFilters[name]?.includes(data)}
                    label={
                      name !== "jewellery_cut" && name !== "jewellery_diamonds"
                        ? data.name
                        : data
                    }
                    value={
                      name !== "jewellery_cut" && name !== "jewellery_diamonds"
                        ? name == "jewellery_stone"
                          ? data.name
                          : data.value
                        : data
                    }
                    onChange={onChange}
                  />
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default CheckBoxInput;
