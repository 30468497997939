import React, { useState, useEffect, useRef } from 'react';
import { httpGet } from '../../utils/api';
import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useFilterContext } from '../../context/SelectedFilterBoxContext';

function SearchProduct() {
  const { REACT_APP_BASE_URL } = process.env;
  const navigate = useNavigate();
  const {setSelectedFilters} = useFilterContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [inFocus, setInFocus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const debounceTimeout = 1000;
  const debounceTimer = useRef(null);

  const loadProducts = async () => {
    // const data = {
    //   productName: searchQuery
    // };
    try {
      const response = await httpGet(
        `${REACT_APP_BASE_URL}/user/product/search?product_name=${searchQuery}`
      );
      if (response.data.status) {
        setProducts((prevProducts) => prevProducts.concat(response.data.data));
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setProducts([]);
    if (searchQuery) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(loadProducts, debounceTimeout);
    }
  }, [searchQuery]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveIndex(activeIndex + 1);
      setSearchQuery(products[activeIndex + 1].text);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (activeIndex - 1 >= 0) {
        setActiveIndex((prevState) => (prevState - 1 >= 0 ? prevState - 1 : 0));
        setSearchQuery(products[activeIndex - 1].text);
      }
    } else if (e.key === 'Escape') {
      setActiveIndex(-1);
      setInFocus(false);
    }
  };

  const handleOnChange = (e) => {
    setInFocus(true);
    setSearchQuery(e.target.value);
    setActiveIndex(-1);
  };

  const findSearchResults = (e) => {
    e.preventDefault();
    if (searchQuery) {
      navigate(`/productListing/products/allProduct?search=${searchQuery}`);
    }
    setSelectedFilters({})
  };

  const redirect = (e, data) => {
    e.preventDefault();
    navigate(`/productDetail/${data?._id}`);
  };

  const handleSelection = (selectedIndex) => {
    const selectedItem = products[selectedIndex];
    console.log('selectedItem selectedItem', selectedItem);
    navigate(`/productDetail/${selectedItem?._id}`);
    setSearchQuery('');
  };

  const handleAll = () => {
    navigate(`/productListing/products/allProduct?search=${searchQuery}`);
  };

  return (
    <>
      <form
        className="search-form"
        onSubmit={(e) => findSearchResults(e)}
        onFocus={() => {
          setInFocus(true);
          setActiveIndex(-1);
        }}
        onBlur={() => {
          setInFocus(false);
          setActiveIndex(-1);
        }}
      >
        <div className="row mx-0 justify-content-center pt-3 header-search position-relative">
          <span className="position-relative header-input-wrap">
            <input
              className="w-100 py-2 px-2"
              type="text"
              placeholder="Search Product or Brand"
              value={searchQuery}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
            />
            <i className="fa-solid fa-magnifying-glass"></i>
            {inFocus && searchQuery !== '' && (
              <ul className="dropdown">
                {products.length === 0 ? (
                  <div className="d-flex flex-wrap w-100 justify-content-center py-1">
                    No Products Found
                  </div>
                ) : (
                  products?.slice(0, 4).map((data, index) => (
                    <>
                      <li
                        className={'dropdown__item'}
                        key={index}
                        onMouseDown={() => handleSelection(index)}
                        onMouseOver={() => setActiveIndex(index)}
                        onMouseOut={() => setActiveIndex(-1)}
                      >
                        <Link className="item-link">
                          <div className="search-suggestion-icon">
                            <img src={data?.images[0]} alt="" />
                          </div>
                          <div className="search-suggestion-text">
                            {data?.productName}
                          </div>
                          <div className="search-suggestion-text">
                            ({data?.mrNo})
                          </div>
                        </Link>
                      </li>
                    </>
                  ))
                )}
                {products.length > 4 && (
                  <li>
                    <div className="d-flex flex-wrap w-100 justify-content-center py-1">
                      <Link onMouseDown={() => handleAll()}>
                        See All Products
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </span>
        </div>
      </form>
    </>
  );
}

export default SearchProduct;
