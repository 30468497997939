import { React, useEffect, useState } from "react";
import "./style.css";
import { Row, Container, Form, Col, Accordion } from "react-bootstrap";
import MultiRangeSlider from "../multiRangeSlider";
import { httpGet, httpPost } from "../../utils/api";
import {
  FRAME_COLOR,
  FRAME_FIT,
  FRAME_MATERIAL,
  FRAME_STYLE,
  LENS_COLOR,
  caseMaterials,
  frameColors,
  jewelery_diamond_color,
  jewelryStones,
  shapes,
  sizeRanges,
  watchMovements,
  watchWaterResistance,
} from "../../constant/filterInputData";
import { useProductData } from "../../context/ProductDataContext";
import { useLocation, useParams } from "react-router-dom";
import CheckBoxInput from "../checkBoxInput/checkBoxInput";
import { useFilterContext } from "../../context/SelectedFilterBoxContext";

function ProductFilterSidebar({ sidebarOpen, setSidebarOpen }) {
  const { REACT_APP_BASE_URL } = process.env;
  const { name } = useParams();
  const { id } = useParams();
  const location = useLocation();
  const { dataObject } = location?.state || {};
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [debouncedMinValue, setDebouncedMinValue] = useState(minValue);
  const [debouncedMaxValue, setDebouncedMaxValue] = useState(maxValue);
  const { setProductData } = useProductData();
  const { addFilter, removeFilter, selectedFilters, setSelectedFilters } =
    useFilterContext();
  const [brandData, setBrandData] = useState([]);
  const [jeweleryData, setJeweleryData] = useState([]);
  const [metalsData, setMetalsData] = useState([]);
  const [stoneData, setStoneData] = useState([]);
  const [isOpenAccordian, setIsOpenAccordian] = useState({
    gender: true,
    barandId: true,
    price: false,
    jewellery_Type_id: true,
    jewellery_metal_id: true,
    jewellery_diamonds: true,
    jewellery_stone: true,
    jewellery_cut: true,
    jewellery_types: true,
    metal_types: true,
    bracelet_material: true,
    case_material: true,
    case_size: true,
    waterResistance: true,
    movement: true,
    diamonds: false,
    frame_style: false,
    frame_colour: true,
    lens_colour: false,
    frame_material: true,
    lens_material: false,
    fit: false,
    colour: true,
    material: false,
  });

  const [filterData, setFilterData] = useState({
    type: name,
    gender: null,
    barandId: null,
    cateId: id,
    // minPrice: 100,
    // price: 10000,

    // Jewellery Keys
    jewellery_Type_id: null,
    jewellery_metal_id: null,
    jewellery_diamonds: [],
    jewellery_stone: [],
    jewellery_cut: [],
    jewellery_types: [],
    metal_types: [],

    // Watch Keys
    bracelet_material: [],
    case_material: [],
    case_size: [],
    waterResistance: [],
    movement: [],
    diamonds: "",

    // Sunglasses keys
    frame_style: [],
    frame_colour: [],
    lens_colour: [],
    frame_material: [],
    lens_material: [],
    fit: [],
    colour: [],
    material: [],
  });

  useEffect(() => {
    let keyName = location?.state?.keyName;
    let data = location?.state?.data;

    console.log("data", data);
    console.log("keyName", keyName)

    if (data && keyName) {
      addFilter(keyName, data);
    }

    if (keyName === "barandId") {
      getJewelryDataByBrandId(data?._id);
      getMetalsDataByBrandId(data?._id);
    }

    if (keyName === "jewellery_Type_id") {
        // addFilter()
        getJewelryDataByBrandId(data?.barandId)
    }     
  }, [location]);

  const toggleAccordion = (section) => {
    setIsOpenAccordian((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        if (key === section) {
          acc[key] = !prevState[key];
        } else {
          acc[key] = prevState[key];
        }
        return acc;
      }, {}),
    }));
  };

  const resetAllFilters = () => {
    setSelectedFilters({});
  };

  useEffect(() => {
    applyFilters();
  }, [filterData, selectedFilters]);

  useEffect(() => {
    getStones();
    if (id && id !== "allProduct") {
      getBrandsDataByCateId(id);
    }
  }, [id]);

  const handleChange = (e, key, selectedItem) => {
    console.log("selected item", selectedItem);
    let { name, value, type, checked } = e.target;
    let allStoneNames = stoneData.map((stone) => stone.name);
    if (name === "barandId") {
      const brandId = value;
      getJewelryDataByBrandId(brandId);
      getMetalsDataByBrandId(brandId);
      addFilter(name, selectedItem);
    } else if (name === "jewellery_Type_id") {
      addFilter(name, selectedItem);
    } else if (name === "jewellery_metal_id") {
      addFilter(name, selectedItem);
    } else if (type === "checkbox" && name !== "checkAll") {
      handleCheckBoxArray(
        selectedFilters,
        setSelectedFilters,
        name,
        value,
        checked
      );
    } else if (name === "checkAll") {
      if (checked) {
        setSelectedFilters({
          ...selectedFilters,
          jewellery_stone: allStoneNames,
        });
        allStoneNames.forEach((stone) => addFilter(stone));
      } else {
        setSelectedFilters({
          ...selectedFilters,
          jewellery_stone: [],
        });
        allStoneNames.forEach((stone) => removeFilter(stone));
      }
    } else if (name === "jewellery_stone") {
      if (value === "no stone" && checked) {
        setSelectedFilters({
          ...selectedFilters,
          jewellery_stone: [],
        });
        removeFilter(name, value);
      } else {
        setFilterData({
          ...selectedFilters,
          jewellery_stone: checked
            ? [...selectedFilters.jewellery_stone, value]
            : selectedFilters.jewellery_stone.filter(
                (stone) => stone !== value
              ),
        });
        if (checked) {
          addFilter(name, value);
        } else {
          removeFilter(name, value);
        }
      }
    } else {
      // setFilterData({ ...filterData, [name]: value });
      addFilter(name, value);
    }
  };

  const getBrandsDataByCateId = async (cateId) => {
    const url = `${REACT_APP_BASE_URL}/user/category/${cateId}/brands`;

    try {
      const response = await httpGet(url);
      if (response?.data?.status) {
        setBrandData(response?.data?.data);
      }
    } catch (error) {
      setBrandData([]);
      console.log(error);
    }
  };

  const getJewelryDataByBrandId = async (barandId) => {
    const url = `${REACT_APP_BASE_URL}/user/jewelry/brand/${barandId}`;

    try {
      const response = await httpGet(url);
      if (response?.data?.status) {
        setJeweleryData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMetalsDataByBrandId = async (brandId) => {
    const url = `${REACT_APP_BASE_URL}/user/metal/brand/${brandId}`;

    try {
      const response = await httpGet(url);
      if (response?.data?.status) {
        setMetalsData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStones = async () => {
    const url = `${REACT_APP_BASE_URL}/user/stones`;
    await httpGet(url)
      .then((response) => {
        setStoneData(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    applyFilters();
  }, [selectedFilters]);

  const applyFilters = async () => {
    const payload = {
      type: name.toLowerCase(),
      cateId: id,
      gender: selectedFilters?.gender,
      barandId: selectedFilters?.barandId?._id,
      minPrice: selectedFilters?.minPrice,
      price: selectedFilters?.price,

      // Jewellery Filter Payload
      jewellery_Type_id:
        name.toLowerCase() === "jewellery"
          ? selectedFilters?.jewellery_Type_id?._id
          : "",
      jewellery_metal_id:
        name.toLowerCase() === "jewellery"
          ? selectedFilters?.jewellery_metal_id?._id
          : "",
      jewellery_diamonds:
        name.toLowerCase() === "jewellery"
          ? selectedFilters?.jewellery_diamonds
          : [],
      jewellery_stone:
        name.toLowerCase() === "jewellery"
          ? selectedFilters?.jewellery_stone
          : [],
      jewellery_cut:
        name.toLowerCase() === "jewellery"
          ? selectedFilters?.jewellery_cut
          : [],
      jewellery_types:
        name.toLowerCase() === "jewellery"
          ? selectedFilters?.jewellery_types
          : [],
      metal_types:
        name.toLowerCase() === "jewellery" ? selectedFilters?.metal_types : [],

      // Watches Filter Payload
      bracelet_material:
        name.toLowerCase() === "watches"
          ? selectedFilters?.bracelet_material
          : [],
      case_material:
        name.toLowerCase() === "watches" ? selectedFilters?.case_material : [],
      case_size:
        name.toLowerCase() === "watches" ? selectedFilters?.case_size : [],
      waterResistance:
        name.toLowerCase() === "watches"
          ? selectedFilters?.waterResistance
          : [],
      movement:
        name.toLowerCase() === "watches" ? selectedFilters?.movement : [],
      diamonds:
        name.toLowerCase() === "watches" ? selectedFilters?.diamonds : "",

      // Sunglasses Filter Payload

      frame_style:
        name.toLowerCase() === "sunglasses" ? selectedFilters?.frame_style : [],
      frame_colour:
        name.toLowerCase() === "sunglasses"
          ? selectedFilters?.frame_colour
          : [],
      lens_colour:
        name.toLowerCase() === "sunglasses" ? selectedFilters?.lens_colour : [],
      frame_material:
        name.toLowerCase() === "sunglasses"
          ? selectedFilters?.frame_material
          : [],
      lens_material:
        name.toLowerCase() === "sunglasses"
          ? selectedFilters?.lens_material
          : [],
      fit: name.toLowerCase() === "sunglasses" ? selectedFilters?.fit : [],
      colour:
        name.toLowerCase() === "sunglasses" ? selectedFilters?.colour : [],
      material:
        name.toLowerCase() === "sunglasses" ? selectedFilters?.material : [],
    };

    try {
      if (id !== "allProduct") {
        await httpPost(
          `${REACT_APP_BASE_URL}/user/products/filters`,
          payload
        ).then((response) => {
          setProductData(response?.data?.data);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedMinValue(minValue);
      setDebouncedMaxValue(maxValue);
    }, 500); // Adjust the delay time as needed

    return () => clearTimeout(delay);
  }, [minValue, maxValue]);

  // useEffect(() => {
  //   setFilterData({
  //     ...filterData,
  //     minPrice: debouncedMinValue,
  //     price: debouncedMaxValue,
  //   });

  //   setSelectedFilters({
  //     ...selectedFilters,
  //     minPrice: debouncedMinValue,
  //     price: debouncedMaxValue,
  //   });
  // }, [debouncedMinValue, debouncedMaxValue]);

  const handleRangeChange = (e) => {
    const { min, max } = e;
    setMinValue(min);
    setMaxValue(max);
  };

  const handleCheckBoxArray = (state, setState, formName, value, checked) => {
    console.log("state", state);
    const selectedValue = value;
    console.log("selected value", selectedValue);

    if (checked) {
      // Checkbox is checked, add the selectedValue to the array
      // setState({
      //   ...state,
      //   [formName]: [...state[formName], selectedValue],
      // });

      addFilter(formName, selectedValue, true);
    } else {
      // Checkbox is unchecked, remove the selectedValue from the array
      // setState({
      //   ...state,
      //   [formName]: state[formName].filter(
      //     (item) => item !== selectedValue
      //   ),
      // });

      addFilter(
        formName,
        state[formName].filter((item) => item !== selectedValue)
      );
    }
  };

  // useEffect(() => {
  //   if (sidebarOpen) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'visible';
  //   }

  //   return () => {
  //     document.body.style.overflow = 'visible';
  //   };
  // }, [sidebarOpen]);

  return (
    <>
      <Row
        className={`sidebar ${sidebarOpen ? "displayBlock" : "displayNone"} `}
      >
        <Container>
          <div className="sidebar-inner">
            <Col className="sidebar-head d-flex align-items-center justify-content-between py-2 border-bottom">
              <h2 className="d-flex align-items-center">
                Filters <i className="fa-solid fa-sliders ms-2"></i>{" "}
                <button
                  type="button"
                  onClick={() => resetAllFilters()}
                  className="filterBtn1"
                >
                  Reset All
                </button>{" "}
              </h2>
              <button
                className="bg-transparent border-0"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </Col>
            <Row className="pt-3 filter-input-wrapper">
              <Accordion
                alwaysOpen
                defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7"]}
              >
                <div className="mb-3">
                  <div
                    className="accordion-header"
                    onClick={() => toggleAccordion("gender")}
                  >
                    <Form.Label>Gender</Form.Label>
                    <i
                      className={`fa-solid fa-chevron-${
                        isOpenAccordian["gender"] ? "up" : "down"
                      }`}
                    ></i>
                  </div>
                  {isOpenAccordian["gender"] && (
                    <div className="accordion-body">
                      <Form.Check
                        className="custom-radio"
                        type="radio"
                        label="Male"
                        checked={selectedFilters?.gender === "male"}
                        onChange={(e) => handleChange(e, "gender")}
                        name="gender"
                        value="male"
                      />
                      <Form.Check
                        className="custom-radio"
                        type="radio"
                        label="Female"
                        checked={selectedFilters?.gender === "female"}
                        onChange={(e) => handleChange(e, "gender")}
                        name="gender"
                        value="female"
                      />
                      {name == "sunglasses" && (
                        <Form.Check
                          className="custom-radio"
                          type="radio"
                          label="Unisex"
                          onChange={(e) => handleChange(e, "gender")}
                          name="gender"
                          value="unisex"
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <div
                    className="accordion-header"
                    onClick={() => toggleAccordion("barandId")}
                  >
                    <Form.Label>Brands & collections</Form.Label>
                    <i
                      className={`fa-solid fa-chevron-${
                        isOpenAccordian["barandId"] ? "up" : "down"
                      }`}
                    ></i>
                  </div>
                  {isOpenAccordian["barandId"] && (
                    <div className="accordion-body">
                      <Accordion>
                        {brandData.map((item, index) => (
                          <Accordion.Item
                            key={item._id}
                            eventKey={index.toString()}
                          >
                            {/* {
                              item?.brandType.length != 0 ? <Accordion.Header>
                                <Form.Check
                                  name="barandId"
                                  type="radio"
                                  label={item.brandName}
                                  value={item._id}
                                  checked={selectedFilters?.barandId?._id === item._id}
                                  onChange={(e) => handleChange(e, "barandId", item)}
                                />
                              </Accordion.Header> : (
                                <Form.Check
                                  name='barandId'
                                  type="radio"
                                  label={item.brandName}
                                  value={item._id}
                                  checked={selectedFilters?.barandId?._id === item._id}
                                  onChange={(e) => handleChange(e, "barandId", item)}
                                />
                              )
                            }
                            {
                              selectedFilters?.barandId?._id === item._id && (
                                <Accordion.Body>
                                  {item?.brandType.map((data) => {
                                    return (
                                      <>
                                        <div className="col-sm-6">
                                          <span className="acc-body-span">{data}</span>
                                        </div>
                                        
                                      </>
                                    )
                                  })}
                                </Accordion.Body>
                              )
                            } */}

                            <Form.Check
                              className="custom-radio"
                              name="barandId"
                              type="radio"
                              label={item.brandName}
                              value={item._id}
                              checked={
                                selectedFilters?.barandId?._id === item._id
                              }
                              onChange={(e) =>
                                handleChange(e, "barandId", item)
                              }
                            />
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  )}
                </div>

                <Form.Group className="mb-5 pb-4">
                  <Form.Label className="mb-3">Price</Form.Label>
                  <MultiRangeSlider
                    min={100}
                    max={10000}
                    onChange={(e) => handleRangeChange(e)}
                  />
                </Form.Group>

                {name == "Jewellery" && (
                  <>
                    <div className="mb-3">
                      <div
                        className="accordion-header"
                        onClick={() => toggleAccordion("jewellery_Type_id")}
                      >
                        <Form.Label>Jewellery Type</Form.Label>
                        <i
                          className={`fa-solid fa-chevron-${
                            isOpenAccordian["jewellery_Type_id"] ? "up" : "down"
                          }`}
                        ></i>
                      </div>
                      {isOpenAccordian["jewellery_Type_id"] && (
                        <div className="accordion-body">
                          <Accordion>
                            {jeweleryData.map((item, index) => (
                              <Accordion.Item
                                key={item._id}
                                eventKey={index.toString()}
                              >
                                {item?.jewelleryType != 0 ? (
                                  <Accordion.Header>
                                    <Form.Check
                                      className="custom-radio"
                                      name="jewellery_Type_id"
                                      type="radio"
                                      label={item.jewelleryName}
                                      value={item._id}
                                      checked={
                                        selectedFilters?.jewellery_Type_id
                                          ?._id === item._id
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "jewellery_Type_id",
                                          item
                                        )
                                      }
                                    />
                                  </Accordion.Header>
                                ) : (
                                  <Form.Check
                                    className="custom-radio"
                                    name="jewellery_Type_id"
                                    type="radio"
                                    label={item.jewelleryName}
                                    value={item._id}
                                    checked={
                                      selectedFilters?.jewellery_Type_id
                                        ?._id === item._id
                                    }
                                    onChange={(e) =>
                                      handleChange(e, "jewellery_Type_id", item)
                                    }
                                  />
                                )}

                                {selectedFilters?.jewellery_Type_id?._id ===
                                  item._id && (
                                  <Accordion.Body>
                                    {item?.jewelleryType.map((data) => {
                                      return (
                                        <>
                                          <Form.Check
                                            className="custom-radio"
                                            type="checkbox"
                                            name="jewellery_types"
                                            value={data}
                                            label={data}
                                            onChange={(e) =>
                                              handleChange(e, "jewellery_types")
                                            }
                                          />
                                        </>
                                      );
                                    })}
                                  </Accordion.Body>
                                )}
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      <div
                        className="accordion-header"
                        onClick={() => toggleAccordion("jewellery_metal_id")}
                      >
                        <Form.Label>Metals</Form.Label>
                        <i
                          className={`fa-solid fa-chevron-${
                            isOpenAccordian["jewellery_metal_id"]
                              ? "up"
                              : "down"
                          }`}
                        ></i>
                      </div>
                      {isOpenAccordian["jewellery_metal_id"] && (
                        <div className="accordion-body">
                          <Accordion>
                            {metalsData.map((item, index) => (
                              <Accordion.Item
                                key={item._id}
                                eventKey={index.toString()}
                              >
                                {item?.metalsType != 0 ? (
                                  <Accordion.Header>
                                    <Form.Check
                                      className="custom-radio"
                                      name="jewellery_metal_id"
                                      type="radio"
                                      label={item.metalsName}
                                      value={item?._id}
                                      checked={
                                        selectedFilters?.jewellery_metal_id
                                          ?._id === item._id
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          e,
                                          "jewellery_metal_id",
                                          item
                                        )
                                      }
                                    />
                                  </Accordion.Header>
                                ) : (
                                  <Form.Check
                                    className="custom-radio"
                                    name="jewellery_metal_id"
                                    type="radio"
                                    label={item.metalsName}
                                    value={item?._id}
                                    checked={
                                      selectedFilters?.jewellery_metal_id
                                        ?._id === item._id
                                    }
                                    onChange={(e) =>
                                      handleChange(
                                        e,
                                        "jewellery_metal_id",
                                        item
                                      )
                                    }
                                  />
                                )}

                                {selectedFilters?.jewellery_metal_id?._id ===
                                  item._id && (
                                  <Accordion.Body>
                                    <div className="d-flex flex-wrap">
                                      <Col lg={6}>
                                        {item?.metalsType.map((data) => {
                                          return (
                                            <>
                                              <Form.Check
                                                className="custom-radio"
                                                type="checkbox"
                                                onChange={(e) =>
                                                  handleChange(e)
                                                }
                                                label={data}
                                                value={data}
                                                name="metal_types"
                                              />
                                            </>
                                          );
                                        })}
                                      </Col>
                                    </div>
                                  </Accordion.Body>
                                )}
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </div>
                      )}
                    </div>

                    <CheckBoxInput
                      label={"Diamonds"}
                      array={jewelery_diamond_color}
                      name={"jewellery_diamonds"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />

                    <CheckBoxInput
                      label={"Stones"}
                      array={stoneData}
                      name={"jewellery_stone"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />

                    <CheckBoxInput
                      label={"Cut"}
                      array={shapes}
                      name={"jewellery_cut"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                  </>
                )}

                {name === "Watches" && (
                  <>
                    <CheckBoxInput
                      label={"Case Size"}
                      array={sizeRanges}
                      name={"case_size"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Case Material"}
                      array={caseMaterials}
                      name={"case_material"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />

                    <Accordion.Item className="mb-3" eventKey="0">
                      <Accordion.Header>
                        <Form.Label>Diamonds</Form.Label>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Form.Check
                          className="custom-radio"
                          type="radio"
                          label="Yes"
                          onChange={(e) => handleChange(e, "diamonds")}
                          name="diamonds"
                          value="yes"
                        />
                        <Form.Check
                          className="custom-radio"
                          type="radio"
                          label="No"
                          onChange={(e) => handleChange(e, "diamonds")}
                          name="diamonds"
                          value="no"
                        />
                      </Accordion.Body>
                    </Accordion.Item>

                    <CheckBoxInput
                      label={"strap/bracelet material"}
                      array={caseMaterials}
                      name={"bracelet_material"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Movement"}
                      array={watchMovements}
                      name={"movement"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Water Resistance"}
                      array={watchWaterResistance}
                      name={"waterResistance"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                  </>
                )}

                {name == "sunglasses" && (
                  <>
                    {/* <CheckBoxInput
                      label={"Frame Style"}
                      array={FRAME_STYLE}
                      name={"frame_style"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Frame Color"}
                      array={FRAME_COLOR}
                      name={"frame_colour"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Lens Color"}
                      array={LENS_COLOR}
                      name={"lens_colour"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    
                    <CheckBoxInput
                      label={"Lens Material"}
                      array={FRAME_COLOR}
                      name={"lens_material"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Fit"}
                      array={FRAME_FIT}
                      name={"fit"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    /> */}

                    <CheckBoxInput
                      label={"Color"}
                      array={frameColors}
                      name={"colour"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                    <CheckBoxInput
                      label={"Material "}
                      array={FRAME_MATERIAL}
                      name={"frame_material"}
                      onChange={(e) => handleChange(e)}
                      toggleAccordion={toggleAccordion}
                      isOpenAccordian={isOpenAccordian}
                    />
                  </>
                )}
              </Accordion>
            </Row>
          </div>
        </Container>
      </Row>
    </>
  );
}

export default ProductFilterSidebar;
