import React, { useState } from "react";
import PinInput from "react-pin-input";
import { Button, Image } from "react-bootstrap";
import { httpPost } from "../../utils/api"; // Import your API function
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import forgotLeftImg from "../../assets/images/forgotleft.png";
import "./style.css";
import logo from "../../assets/images/goldlogo.png";

function VerifyOTP() {
  const { REACT_APP_BASE_URL } = process.env;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const email = localStorage.getItem("email");

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const handleOtpVerification = async () => {
    const data = {
      email: email,
      otp: otp,
    };
    if (!/^\d{4}$/.test(otp)) {
      setError("Invalid OTP format. Please enter a 4-digit OTP.");
      return;
    }

    try {
      const response = await httpPost(
        `${REACT_APP_BASE_URL}/auth/verify_otp`,
        data
      );
      if (response?.data?.status) {
        toast.success("OTP is verified successfully");
        navigate("/reset-password");
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await httpPost(`${REACT_APP_BASE_URL}/auth/resend_otp`, {
        email: email,
      });
      if (response.status === 200) {
        toast.success("OTP resent successfully");
      } else {
        toast.error("Failed to resend OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="forgot-page-outer">
        <div className="d-flex">
          <div className="col-lg-7">
            <div className="sign-up-left forgot-left d-flex flex-wrap align-items-center ps-4">
              <Image src={forgotLeftImg} />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="signUp px-5 py-5">
              <div className="container">
                <form>
                  <div className="row  justify-content-center">
                    <h1 className="text-center w-100 mt-3 responsive_logo">
                      <Link to={"/"}>
                        <Image src={logo} />
                      </Link>
                    </h1>
                    <h2 className="text-center mb-3 pb-3">OTP Verfication</h2>
                    <div className="row flex-column align-items-center ">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <PinInput
                          length={4}
                          onChange={handleOtpChange}
                          initialValue=""
                          type="numeric"
                          inputMode="numeric"
                          style={{
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          inputStyle={{
                            borderColor: "grey",
                            borderWidth: "2px",
                            color: "black",
                          }}
                          inputFocusStyle={{ borderColor: "black" }}
                        />
                        {/* {error && <Alert variant="danger">{error}</Alert>} */}
                      </div>

                      <Button
                        type="button"
                        className="content-btn register-btn mt-4"
                        onClick={handleOtpVerification}
                      >
                        Verify OTP{" "}
                        <span className="arrowUpRight mx-2">
                          <i className="fas fa-arrow-up"></i>
                        </span>
                      </Button>
                      <Button
                        type="button"
                        className="content-btn new-btn  register-btn mt-4"
                        onClick={handleResendOTP}
                      >
                        Resend OTP{" "}
                        <span className="arrowUpRight mx-2">
                          <i className="fas fa-arrow-up"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOTP;
