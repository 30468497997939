import React, { useState } from 'react';
import './style.css';
import { Row, Container, Image, Button } from 'react-bootstrap';
import watch from '../../../../assets/images/watch.png';
import sunglass from '../../../../assets/images/glass11.png';
import ring from '../../../../assets/images/ring11.png';

import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { useCategory } from '../../../../context/categoryContext';
import { useFilterContext } from '../../../../context/SelectedFilterBoxContext';

function CategorySlider() {
  const { category } = useCategory();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const {setSelectedFilters} = useFilterContext();

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '100px',
    slidesToShow: 3,
    speed: 500,
    afterChange: (current) => {
      setIndex(current);
      console.log(current, 'current');
    },
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2.3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2.3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleNavigate = () => {
    // if (index >= 0 && index < category.length) {

    //     const selectedCategory = category[index];
    //     console.log(selectedCategory, "selectedCategory")
    //     navigate(`/productListing/${selectedCategory.name}/${selectedCategory._id}`);
    // } else {
    //     console.error("Invalid index or category data");
    // }

    if (index === 0 || index === 3) {
      navigate(`/productListing/${category[1]?.name}/${category[1]._id}`);
    } else if (index === 1 || index === 4) {
      navigate(`/productListing/${category[2]?.name}/${category[2]._id}`);
    } else {
      navigate(`/productListing/${category[0]?.name}/${category[0]._id}`);
    }
    setSelectedFilters({});

  };

  return (
    <>
      <div className="center-slider my-4 py-5 position-relative">
        <Container>
          <div className="category-wrapper">
            <div className="category-center-bg-inner"></div>
            <Row className="row-head text-center flex-column align-items-center">
              <h2>Explore Categories</h2>
              <h6>
                {index == 0 || index == 3
                  ? 'Embrace the legacy of timekeeping excellence, as our exceptional watches capture the essence of tradition, innovation, and enduring beauty.'
                  : index == 1 || index == 4
                    ? 'Experience the perfect fusion of fashion and functionality, as our iconic sunglasses not only enhance your style but also provide unrivaled visual comfort and sun protection.'
                    : 'Adorn yourself with the brilliance of exquisite craftsmanship, as our luxurious jewelry unveils your innate elegance and celebrates your individuality.'}
              </h6>
            </Row>
            <Row className="category-swiper position-relative">
              <h3 className="carousel-heading">
                {index == 0 || index == 3
                  ? 'Watches'
                  : index == 1 || index == 4
                    ? 'Sunglasses'
                    : 'JEWELERY'}
              </h3>
              <Slider {...settings}>
                <Image src={watch} />
                <Image src={sunglass} />
                <Image src={ring} />
                <Image src={watch} />
                <Image src={sunglass} />
                <Image src={ring} />
              </Slider>
            </Row>
            <div className="d-flex justify-content-center mt-3">
              <Button
                className="content-btn"
                type="button"
                onClick={() => handleNavigate()}
              >
                Explore{' '}
                {index == 0 || index == 3
                  ? 'Watches'
                  : index == 1 || index == 4
                    ? 'Sunglasses'
                    : 'JEWELERY'}
                <span className="arrowUpRight mx-2">
                  <i className="fas fa-arrow-up"></i>
                </span>
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default CategorySlider;
